import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import * as ApiModels from '@typings/api-models';

export type WorkflowCollaborator = Pick<
ApiModels.UserWorkflowCollaborator,
| 'id'
| 'source'
| 'canAssign'
| 'canInviteCollaborators'
| 'canReceiveCustomerNotifications'
| 'canHavePermissionsChanged'
| 'name'
| 'color'
| 'logo'
| 'email'
| 'roleLabel'
| 'personId'
>;

type UserWorkflowContextValue = {
  collaborators: Array<WorkflowCollaborator>;
  setCollaborators: (collabs: WorkflowCollaborator[]) => void;
};

const UserWorkflowContext = createContext<UserWorkflowContextValue>({
  collaborators: [],
  setCollaborators: () => {},
});

const UserWorkflowContextProvider: React.FC<PropsWithChildren<{
  collaborators: Array<WorkflowCollaborator>
}>> = ({ children, collaborators: originCollaborators }) => {
  const [collaborators, setCollaborators] = useState<WorkflowCollaborator[]>(originCollaborators);

  const values = useMemo(() => ({
    collaborators,
    setCollaborators,
  }), [collaborators]);

  return (
    <UserWorkflowContext.Provider value={values}>
      {children}
    </UserWorkflowContext.Provider>
  );
};

const useUserWorkflowContext = (): UserWorkflowContextValue => useContext(UserWorkflowContext);

export { UserWorkflowContextProvider };
export default useUserWorkflowContext;
