import { FC, useState } from 'react';
import Modal from '@components/Modal';
import { MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';
import getAttachmentIcon from '@helpers/getAttachmentIcon';
import Button from '@components/DesignLibrary/Button';
import s from './SkipTaskConfirmationModal.module.scss';

type Props = {
  onClose: () => void;
  onConfirm: () => unknown;
  taskType: MESSAGE_ATTACHMENT_REFERENCE_TYPES;
};

const SkipTaskConfirmationModal: FC<Props> = ({ onClose, onConfirm, taskType }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const IconComponent = getAttachmentIcon(taskType);

  const handleClick = (): void => {
    setIsSubmitting(true);
    onConfirm();
  };

  return (
    <Modal title='Please Confirm' onClose={onClose} >
      <div className={s.container}>
        <div className={s.main}>
          <figure>
            {IconComponent && <IconComponent size="40" weight='fill' color="var(--color-primary)"/>}
          </figure>
          <p className='body-lg-bold'>{'You are about to skip this task'}</p>
          <p className='body-sm'>{'Skipping will make this task not required for your client to continue. Are you sure you want to skip it?'}</p>
        </div>
        <Button
          onClick={handleClick}
          variant={'destructive'}
          size={'md'}
          loading={isSubmitting}
        >
          Skip task
        </Button>
      </div>
    </Modal>
  );
};

export default SkipTaskConfirmationModal;
