import { FC, useState } from 'react';
import { Asset, Creator, NoodleProductTypes, Price, Product, ProductType } from '@typings/graphql-models';
import EditButton from '@components/EditButton';
import ProfileProductIcons from '@components/ProfileProductIcons';
import SmallestPrice from '@components/SmallestPrice';
import CustomLink from '@components/CustomLink';
import CaretRight from '@components/Icons/CaretRight';
import Shimmer from '@components/Shimmer';
import Buttons from '@components/Buttons';
import hasNoodleProductType from '@helpers/hasNoodleProductType';
import s from './ServiceCard.module.scss';

type Props = {
  to: string;
  creator: Pick<Creator, 'name'> & {
    image?: Pick<Asset, 'url'> | null;
  };
  product: Pick<Product, 'title' | 'isActive'> & {
    prices: Array<Pick<Price, 'price' | 'id' | 'isActive' | 'currency'>>;
    productTypes: Pick<ProductType, 'title' | 'noodleProductType'>[];
  };
  canEdit: boolean;
  isFeatured?: boolean;
  newTab?: boolean;
};

const ServiceCard: FC<Props> = ({ to, creator, product, canEdit, isFeatured, newTab }) => {
  const [isShimmering, setIsShimmering] = useState(false);
  return (
    <CustomLink
      to={(isShimmering && !newTab) ? undefined : to}
      onClick={() => setIsShimmering(true)}
      className={s[`serviceCard${isFeatured ? 'Featured' : ''}${(isShimmering && !newTab) ? '__disabled' : ''}`]}
      newTab={newTab}
    >
      <ProfileProductIcons
        isInactive={!product.isActive}
        isOnCreatorPage
        creatorImage={creator.image?.url}
        creatorName={creator.name || 'Creator'}
        type={product.productTypes?.map(t => t.noodleProductType)[0] || NoodleProductTypes.Lite}
      />
      {isFeatured && <div className={s.serviceCardFeatured__label}>Featured</div>}
      {isShimmering && !newTab && !isFeatured && <Shimmer className={s.serviceCard__shimmer} />}
      {canEdit && (
        <div className={s.serviceCard__edit}>
          <EditButton isMock />
        </div>
      )}
      <div className={s.serviceCard__copy}>
        <strong style={(!product.isActive && { color: 'var(--color-gray-75)' }) || undefined}>{product.title}</strong>
        {!hasNoodleProductType(product, NoodleProductTypes.Consultation) && <SmallestPrice canEdit={canEdit} prices={product?.prices || []} />}
      </div>
      {!canEdit && !isFeatured && <CaretRight className={s.serviceCard__caret} />}
      {!product.isActive && <small className={s.serviceCard__inactive}>Inactive</small>}
      {isFeatured && (
        <Buttons isShimmering={isShimmering} isThird isFullWidth className={s.serviceCardFeatured__cta}>
          Get it now
        </Buttons>
      )}
    </CustomLink>
  );
};

export default ServiceCard;
