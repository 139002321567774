import { FC } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import convertLinksToAnchors from '@helpers/convertLinksToAnchors';
import convertEmailsToAnchors from '@helpers/convertEmailsToAnchors';
import * as ApiModels from '@typings/api-models';

import s from './RenderText.module.scss';

const ALLOWED_ATTR = ['target', 'rel', 'href', 'src', 'style'];

type TextProps = {
  comment: {
    text?: string | null;
    richText?: {
      html: string;
    } | null;
    taggedUsers?: Pick<ApiModels.Person, 'id' | 'name'>[];
  } | null
};

const replaceTaggedUsers = ({ message, taggedUsers }: { message: string; taggedUsers: Pick<ApiModels.Person, 'id' | 'name'>[] }): string => {
  if (taggedUsers.length === 0) {
    return message;
  }
  const taggedUserIds: string[] = (message.match(/({{@)(.*?)(?=}})/gm) || []).map(id => id.replace('{{@', ''));
  let replacedMessage = message;
  taggedUsers.forEach(user => {
    replacedMessage = replacedMessage.replace(new RegExp(`{{@${user.id}}}`, 'g'), `<span style="color: var(--color-noodle);">@${user.name || user.id}</span>`);
  });
  taggedUserIds.forEach(id => {
    if (!taggedUsers.find(u => u.id === id)) {
      replacedMessage = replacedMessage.replace(new RegExp(`{{@${id}}}`, 'g'), '<span style="color: var(--color-noodle);">@{Deleted User}</span>');
    }
  });
  return replacedMessage;
};

const RenderText: FC<TextProps> = ({ comment }) => {
  let message = comment?.richText?.html || comment?.text || '';
  const taggedUsers = comment?.taggedUsers || [];

  if (!message) {
    return null;
  }

  message = convertLinksToAnchors(message);
  message = convertEmailsToAnchors(message);
  message = replaceTaggedUsers({ message, taggedUsers });
  message = message.replace(/\n/g, '<p></p>');

  return (
    <div
      className={s.text}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message, { ALLOWED_ATTR }) }}
    />
  );
};

export default RenderText;
