import { FC } from 'react';
import Modal from '@components/Modal';
import ScheduleBooking, { type ScheduleBookingProps } from '@lib/ScheduleBooking';

type Props = ScheduleBookingProps & {
  onClose: () => void;
};

const ScheduleBookingModal: FC<Props> = ({ onClose, ...restProps }) => (
  <Modal title="Schedule Booking" onClose={onClose} >
    <ScheduleBooking {...restProps} />
  </Modal>
);

export default ScheduleBookingModal;
