import CustomLink from "@components/CustomLink";
import ChecksOverlapping from "@components/Icons/ChecksOverlapping";
import s from './CommentContent.module.scss';

type Props = {
  link: string;
};

const isWithCalendly = (link: string): boolean => /^https:\/\/calendly.com\//.test(link);
const isWithGoogle = (link: string): boolean => /^https:\/\/calendar.app.google\//.test(link);

const genSubText = (link: string): string => {
  if (isWithCalendly(link)) {
    return 'with Calendly';
  }
  if (isWithGoogle(link)) {
    return 'with Google Calendar';
  }

  const url = new URL(link);
  return `${url.host}${url.pathname}`;

};
const BookingLink: React.FC<Props> = ({ link }) => {
  const subText = genSubText(link);
  return (
    <div className={s['booking-link']}>
      <div className={s['text-part']}>
        <CustomLink newTab to={link}>Book your session</CustomLink>
        {subText && (<span className='caption'>{subText}</span>)}
      </div>
      <ChecksOverlapping />
    </div>
  );
};

export default BookingLink;