import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/custom-terms/{id}/skip']['POST'];
type Input = ThisApi['params'];

const skipCustomTerms = async ({ id }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/custom-terms/${id}/skip`);
  return response.data;
};

export default skipCustomTerms;