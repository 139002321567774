import React, { FC, useContext, useEffect, useState } from 'react';

import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import Buttons from '@components/Buttons';
import Signature from '@components/Icons/Signature';
import CheckCircle from '@components/Icons/CheckCircle';
import { useRouter } from 'next/router';
import { CustomTerms, CustomTermsTemplate, MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';
import skipCustomTerms from '@tsClient/customTerms/skipCustomTerms';
import { useOpenCustomTermsPane, useOpenPreviewDocumentPane } from '@panes/hooks';
import SkipTaskConfirmationModal from '@modals/SkipTaskConfirmationModal';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import * as format from '@format';
import s from './CustomTermsCard.module.scss';

type Props = {
  customTerms: Pick<CustomTerms, 'id' | 'termsText' | 'agreedAt' | 'skippedAt' | 'documentId'>;
  template: Pick<CustomTermsTemplate, 'id' | 'title'> & {
    creator: { id: string; slug: string; name?: string };
  };
  setIsFulfilled?: (f: boolean) => void;
  setIsSkipped?: (f: boolean) => void;
  onChange?: () => void;
  canClick?: boolean;
  canSkip: boolean;
  isInDashboard?: boolean;
};

const CustomTermsCard: FC<Props> = ({ setIsFulfilled, setIsSkipped, onChange, customTerms, template, canClick, canSkip, isInDashboard }) => {
  const router = useRouter();
  const openCustomTermsPane = useOpenCustomTermsPane();
  const openDocumentPane = useOpenPreviewDocumentPane();
  const [skipTaskModalOpen, setSkipTaskModalOpen] = useState(false);
  const { isInUserProfile } = useContext(UserProfileContext);

  const hasAgreed = Boolean(customTerms.agreedAt);
  const isSkipped = Boolean(customTerms.skippedAt);
  const isGenerating = !customTerms.termsText;

  useEffect(() => {
    setIsFulfilled?.(hasAgreed);
  }, [hasAgreed, setIsFulfilled]);

  useEffect(() => {
    setIsSkipped?.(isSkipped);
  }, [isSkipped, setIsSkipped]);

  const handleSkipTask = async (): Promise<void> => {
    await skipCustomTerms({ id: customTerms.id });
    setSkipTaskModalOpen(false);
    if (onChange) onChange();
  };

  const handleClick = (): void => {
    if(isInDashboard || isInUserProfile) {
      if (customTerms.documentId) {
        openDocumentPane({ previewDocumentId: customTerms.documentId });
      } else {
        openCustomTermsPane({ customTermsId: customTerms.id });
      }
    } else{
      router.push(
        getUrl(IDENTIFIERS.CUSTOM_TERMS_DETAILS, {
          creatorSlug: template.creator.slug,
          customTermsId: customTerms.id,
        }),
      );
    }
  };

  const getCustomerTitle = (): string => {
    if (template.creator.name) {
      if (isGenerating) {
        return `${template.creator.name} is generating your agreement.`;
      }
      return `${template.creator.name} ${hasAgreed ? 'has requested' : 'is requesting'} you to sign the agreement`;
    }
    return 'Agreement to sign';
  };

  const getCustomerDescription = (): string => {
    if (isGenerating) {
      return template.title || 'Custom terms';
    }
    return hasAgreed ? `${template.title} is signed` : `Please sign ${template.title}`;
  };

  return (
    <>
      {skipTaskModalOpen && (
        <SkipTaskConfirmationModal
          onClose={() => setSkipTaskModalOpen(false)}
          onConfirm={handleSkipTask}
          taskType={MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS}
        />
      )}
      <Buttons
        disabled={!canClick || (isSkipped && !canSkip)}
        className={s.container}
        isFullWidth
        isWrapper
        onClick={handleClick}
        style={isSkipped ? { backgroundColor: 'var(--color-gray-25)', borderRadius: 8 } : {}}
      >
        <figure style={(hasAgreed && { backgroundColor: 'var(--color-primary)', borderColor: 'var(--color-primary)' }) || {}}>
          <Signature weight="fill" color={hasAgreed ? 'var(--color-gray-0)' : 'var(--color-primary)'} size={24} />
          {hasAgreed && (
            <div className={s.complete}>
              <CheckCircle size={16} weight="fill" color="var(--color-success)" />
            </div>
          )}
        </figure>
        <div className={s.paymentText}>
          {isInDashboard
            ? (
              <div className={isGenerating ? s.isGenerating : undefined}>
                <p className="caption">Terms request{customTerms.agreedAt && ` completed on ${format.datetime.withoutTimezone({datetime:customTerms.agreedAt})}`}</p>
                <p className="body-sm-bold">{template.title}</p>
                {isGenerating && <p className='caption'>Click here to generate</p>}
              </div>
            )
            : (
              <>
                <p className="caption">
                  {getCustomerTitle()}
                </p>
                <p className="body-sm-bold">
                  {getCustomerDescription()}
                </p>
              </>
            )}
          {isSkipped && <p className='caption'>This task is no longer required.</p>}
        </div>
        {canClick && (!isSkipped || canSkip) && <div className={s.open} {...!hasAgreed && !isInDashboard && {
          style: {
            backgroundColor: 'var(--color-primary)',
            border: '1px solid var(--color-primary)',
            color: 'var(--color-gray-0)',
          },
        }}>View</div>}
      </Buttons>
      {canSkip && !isSkipped && (
        <Buttons
          onClick={(event) => {
            event.stopPropagation();
            setSkipTaskModalOpen(true);
          }}
          className={s.skip}
        >
          Skip
        </Buttons>
      )}
    </>
  );
};

export default CustomTermsCard;
