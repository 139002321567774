import { FC, useEffect, useState } from 'react';
import Modal from '@components/Modal';
import Button from '@components/DesignLibrary/Button';
import useNoodleApi from '@/hooks/useNoodleApi';
import getTeamMembersWithAvailability from '@/tsClient/scheduling/getTeamMembersWithAvailability';
import Chips from '@/components/Chips';
import ProgressIndicator from '@/components/ProgressIndicator';
import s from './SelectTeamMemberAvailabilityModal.module.scss';

type Props = {
  onClose: () => void;
  onSelect: (teamMemberPersonId: string) => void;
  productId: string;
  teamMemberPersonId: string | null;
};

const SelectTeamMemberAvailabilityModal: FC<Props> = ({ onClose, onSelect, productId, teamMemberPersonId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getData: getTeamMembersWithAvailabilityFn, fetchingState, data: teamMembers } = useNoodleApi(getTeamMembersWithAvailability);
  const [selectedTeamMemberPersonId, setSelectedTeamMemberPersonId] = useState<string | null>(teamMemberPersonId);

  useEffect(() => {
    getTeamMembersWithAvailabilityFn({ productId });
  }, [productId, getTeamMembersWithAvailabilityFn]);

  const handleClick = (): void => {
    setIsSubmitting(true);
    if (selectedTeamMemberPersonId) {
      onSelect(selectedTeamMemberPersonId);
    }
  };

  return (
    <Modal title="Select Team Members Availability To Use" onClose={onClose} >
      <div className={s.container}>
        {fetchingState.isFetching && <ProgressIndicator isCentered />}
        {teamMembers && !fetchingState.isFetching && (
          <>
            {teamMembers.length !== 0
              ? (
                <>
                  <Chips
                    data={teamMembers.map(tm => ({
                      id: tm.id,
                      label: tm.name || tm.email || tm.id,
                      userImage: {
                        color: tm.primaryColour?.hex,
                        name: tm.name || undefined,
                        url: tm.image?.url,
                      },
                    }))}
                    wrap
                    selectedItem={selectedTeamMemberPersonId}
                    onItemClick={id => id && setSelectedTeamMemberPersonId(id)}
                  />
                  <Button onClick={handleClick} variant={'primary'} size={'md'} loading={isSubmitting} fullWidth>
                  Select Member
                  </Button>
                </>
              )
              : (
                <p className='body-sm'>No team members available</p >
              )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default SelectTeamMemberAvailabilityModal;
