import { useRef, useState, useEffect, useCallback } from 'react';
import Player from '@vimeo/player';
import mime from 'mime-types';

import { getVimeoVideoStatus } from '@tsClient';
import MediaLoader from 'components/MediaLoader';
import Noodle from '@components/Icons/Noodle';
import DownloadSimple from '@components/Icons/DownloadSimple';
import Buttons from '@components/Buttons/Buttons';
import ProgressIndicator from '@components/ProgressIndicator';
import { mixpanelTrack } from '@providers/Mixpanel';
import s from './VimeoPlayer.module.scss';

type PropsType = {
  id?: string | undefined;
  style?: React.CSSProperties;
  videoId?: number | undefined;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  autopause?: boolean;
  autoplay?: boolean;
  responsive?: boolean;
  isSmall?: boolean;
  isExtraSmall?: boolean;
  downloadUrl?: string | null;
  contentType?: string | null;
  isMediaAttachment?: boolean;
  isBroadcast?: boolean;
  isByte?: boolean;
};

const VimeoPlayer: React.FC<PropsType> = ({
  id,
  downloadUrl,
  style,
  videoId,
  loop = false,
  muted = false,
  controls = true,
  autopause = true,
  autoplay = false,
  responsive = true,
  isExtraSmall = false,
  contentType,
  isMediaAttachment,
  isBroadcast,
  isByte,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ isFetching: true, isTranscoding: true });
  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const [isHorizontalBroadcast, setIsHorizontalBroadcast] = useState(false);

  const checkVideoStatus = useCallback(() => {
    getVimeoVideoStatus({ uri: `/videos/${videoId}` }).then(data => {
      setState(prevState => ({
        ...prevState,
        isFetching: false,
        isTranscoding: data.transcode?.status !== 'complete',
      }));
      if (data.transcode?.status !== 'complete') {
        setTimeout(checkVideoStatus, 30000);
      }
    });
  }, [videoId]);

  const downloadVideo = async (): Promise<void> => {
    setIsPreparingDownload(true);
    if (downloadUrl && contentType) {
      const data = await fetch(downloadUrl);
      const blob = await data.blob();
      const tempUrl = URL.createObjectURL(blob);
      const aTag = document.createElement('a');
      aTag.href = tempUrl;
      aTag.download = `${downloadUrl.replace(/^.*[\\/]/, '')}.${mime.extension(contentType)}`;
      document.body.appendChild(aTag);
      aTag.click();
      URL.revokeObjectURL(tempUrl);
      aTag.remove();
      setIsPreparingDownload(false);
    }
  };

  useEffect(() => {
    checkVideoStatus();
  }, [checkVideoStatus]);

  useEffect(() => {
    getVimeoVideoStatus({ uri: `/videos/${videoId}` });

    if (container.current && !state.isTranscoding) {
      const player = new Player(container.current, {
        autopause,
        autoplay,
        byline: false,
        controls,
        dnt: false,
        id: videoId,
        loop,
        muted,
        quality: '2K',
        responsive,
        title: false,
      });
      player.on('play', () => mixpanelTrack('Play vimeo', { videoId }));
      player.on('pause', () => mixpanelTrack('Pause vimeo', { videoId }));
      player.setVolume(1);
    }
  }, [container, state, autopause, autoplay, controls, loop, muted, responsive, videoId]);

  // get iframe width and height ratio when iframe is ready and set the container width
  useEffect(() => {
    if (!state.isFetching && !state.isTranscoding && isMediaAttachment) {
      setTimeout(() => {
        const iframe = container.current?.querySelector('iframe');
        if (iframe) {
          const iframeWidth = iframe.clientWidth;
          const iframeHeight = iframe.clientHeight;
          const ratio = iframeWidth / iframeHeight;

          if (container.current) {
            container.current.style.width = `${container.current.clientHeight * ratio}px`;

            // if isExtraSmall
            if (isExtraSmall) {
              if (ratio > 1.001) {
                container.current.style.width = '160px';
                container.current.style.height = '160px';
              } else {
                container.current.style.width = '160px';
                container.current.style.height = 'auto';
              }
            }

            // if horizontal video
            if (ratio > 1.001 && !isExtraSmall && !isBroadcast) {
              container.current.style.width = '300px';
              container.current.style.height = '400px';
            }
            // if horizontal broadcast
            if (ratio > 1.001 && !isExtraSmall && isBroadcast) {
              setIsHorizontalBroadcast(true);
              container.current.style.width = `${wrapper.current?.clientWidth}px`;
              container.current.style.height = `${container.current.clientWidth / ratio}px`;
            }
          }
        }
      }, 1000);
    }
  }, [state, isExtraSmall, isMediaAttachment]);

  return (
    <>
      {state.isFetching || state.isTranscoding
        ? (
          <div className={s.shimmer} style={(!isMediaAttachment && !isBroadcast && { width: '100%' }) || {}}>
            <MediaLoader
              isProcessMessage={true}
              message={state.isFetching || !state.isTranscoding ? 'Loading...' : "We're processing your video..."}
              subMessage={
                !state.isFetching || !state.isTranscoding
                  ? 'This can take a few minutes depending on its length. You can refresh this page, or leave and come back!'
                  : undefined
              }
            />
          </div>
        )
        : (
          <div ref={wrapper} className={s.wrapper} style={(((!isMediaAttachment && !isBroadcast) || isHorizontalBroadcast) && { width: '100%' }) || {}}>
            <div
              id={id}
              className={s.container}
              style={{
                height: (isExtraSmall && 'auto') || (isMediaAttachment && 400) || 'auto',
                minHeight: (!isMediaAttachment && '160px') || undefined,
                width: (isExtraSmall && 160) || (isMediaAttachment && 248) || '100%',
                ...style,
              }}
              ref={container}
            />
            {downloadUrl && !isByte && (
              <Buttons isWrapper onClick={downloadVideo} disabled={isPreparingDownload} className={s.download}>
                <div>{isPreparingDownload ? <ProgressIndicator size={16} /> : <DownloadSimple weight="fill" size={16} />}</div>
              </Buttons>
            )}
            <div className={s.logo}>
              <Noodle width={16} height={16} fill="white" />
            </div>
          </div>
        )}
    </>
  );
};

export default VimeoPlayer;
export const vimeoUriToId = (vimeoUri: string): number => Number(vimeoUri?.replace('/videos/', ''));
