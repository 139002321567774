import * as ApiModels from '@typings/api-models';
import ProductIcon from '@components/ProductIcon';
import CustomLink from '@components/CustomLink';
import SmallestPrice from '@components/SmallestPrice';
import CaretRight from '@components/Icons/CaretRight';
import s from './HandbookMessage.module.scss';

type ThisImage = Pick<ApiModels.Asset, 'url' | 'mimeType' | 'size'>;
type ThisHandbookStep = Pick<ApiModels.HandbookStep, 'id'> & {
  photos: Array<ThisImage>;
};

type Props = {
  handbook: Pick<ApiModels.Product, 'title' | 'shortDescription'> & {
    link: string;
    image: {
      url?: string;
    };
    handbookSteps?: Array<ThisHandbookStep>;
    productTypes: Array<Pick<ApiModels.ProductType, 'noodleProductType'>>;
    prices?: Array<Pick<ApiModels.Price, 'price' | 'currency' | 'isActive'>>;
  };
};

const HandbookMessage: React.FC<Props> = ({ handbook }) => {
  const handbookStepsPhotos = handbook?.handbookSteps?.[0]?.photos?.[0];
  const mimeType = handbookStepsPhotos ? handbookStepsPhotos?.mimeType : null;
  return (
    <CustomLink className={s.item} to={handbook.link ?? '#'}>
      <div className={s.container}>
        <div className={s.icon}>
          <ProductIcon size={24} noodleProductType={ApiModels.NoodleProductTypes.Handbook} mimeType={mimeType} />
        </div>
        <div>
          <strong>{handbook.title}</strong>
          <SmallestPrice canEdit={false} prices={handbook.prices || []} />
        </div>
        <CaretRight className={s.caret} />
      </div>
    </CustomLink>
  );
};

export default HandbookMessage;
