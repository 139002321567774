import Image from 'next/image';
import { getCorrectFileUrl } from '@helpers/urlsHelper';

import s from './ChatImage.module.scss';

type Props = {
  url: string;
  alt?: string;
};

const ChatImage: React.FC<Props> = ({ url, alt = 'image' }) => {
  const imageUrl = getCorrectFileUrl(url);

  return (
    <div className={s['chat-image']}>

      <Image loading="lazy" src={imageUrl} alt={alt} width={800} height={800} />
    </div>
  );
};

export default ChatImage;
