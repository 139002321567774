import { ReactElement, useEffect, useRef, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import { useToast } from '@hooks';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Modal from '@components/Modal';
import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import XCircle from '@components/Icons/XCircle';
import useUserWorkflowContext from '@/hooks/useUserWorkflowContext';
import CreditFinancialReport from './CreditFinancialReport';
import SelectCustomer from './SelectCustomer';
import ApprovalRequestSent from './ApprovalRequestSent';
import s from './CreditReportModal.module.scss';

const steps = [
  'Credit and Financial Report',
  'Select Debtor',
  'Select Debtor',
  '',
];

type Props = {
  creator: Pick<ApiModels.Creator, 'slug'>;
  creditReportId: string;
  userWorkflowId?: string;
  onClose: () => void;
  onConfirm: () => unknown;
};

type Customer = Pick<ApiModels.Person, 'id' | 'name' | 'email'>;

const CreditReportModal = ({ creator, creditReportId, userWorkflowId, onConfirm, onClose }: Props): ReactElement => {
  const { collaborators } = useUserWorkflowContext();
  const customers = (collaborators?.filter(collaborator => collaborator.source === 'customer' || collaborator.source === 'organization-member') ?? [])
    .concat(
      collaborators?.filter(
        collaborator =>
          collaborator.source !== 'creator'
          && collaborator.source !== 'creator-team-member'
          && collaborator.source !== 'customer'
          && collaborator.source !== 'organization-member',
      ) ?? [],
    );

  const [currentStep, setCurrentStep] = useState(0);
  const [currentProduct, setCurrentProduct] = useState<{ productId: string; price: number; currency: string; numBookings: number }>({
    currency: '',
    numBookings: 1,
    price: 0,
    productId: '',
  });
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [secondCustomer, setSecondCustomer] = useState<Customer | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const { fetchingState: sendingApprovalRequest, getData: sendApprovalRequestFn } = useNoodleApi(tsClient.creditReportsB2C.sendApprovalRequest, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error sending approval request for credit report. Please try again.',
    ],
    toastOnError: true,
  });

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const handleSubmit = async (debtor1: Customer, debtor2?: Customer): Promise<void> => {
    if (!currentProduct.price || !currentProduct.productId || !userWorkflowId || !creator.slug) {
      return;
    }

    const response = await sendApprovalRequestFn({
      clientIds: debtor2 ? [debtor1.id, debtor2.id] : [debtor1.id],
      creditReportId,
    });

    if (response.data?.message === 'OK') {
      setCurrentStep(steps.length - 1);
    }
  };

  const nextStep = async (selectedCustomer?: Customer): Promise<void> => {
    if (currentStep === steps.length - 1) {
      onConfirm();
    } else if (currentStep === 1) {
      if (currentProduct.numBookings === 1) {
        await handleSubmit(selectedCustomer!);
      } else {
        setCurrentStep(2);
      }
    } else if (currentStep === 2) {
      await handleSubmit(customer!, selectedCustomer);
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const handleSelectCustomer = (selectedCustomer: Customer, isSecondary: boolean): void => {
    if (isSecondary) {
      setSecondCustomer(selectedCustomer);
    } else {
      setCustomer(selectedCustomer);
    }

    nextStep(selectedCustomer);
  };

  return (
    <Modal
      hideBottomInset
      title={`${steps[currentStep]}${currentProduct?.numBookings > 1 && (currentStep === 1 || currentStep === 2) ? currentStep: ''}`}
      onClose={sendingApprovalRequest.isFetching
        ? undefined
        : (currentStep === steps.length - 1 || currentStep === 0)
          ? onClose
          : () => setCurrentStep((prev) => prev - 1)
      }
      ref={modalRef}
      closeIcon={currentStep === 0 || currentStep === steps.length - 1
        ? <XCircle weight="fill" size={24} color="var(--color-gray-100)" />
        : <ArrowCircleLeft weight="fill" size={24} color="var(--color-gray-100)" />}
    >
      <div className={s.content}>
        {currentStep === 0 && <CreditFinancialReport nextStep={nextStep} onSelectProduct={setCurrentProduct} />}
        {currentStep === 1 && (
          <SelectCustomer
            selectedCustomer={customer}
            customers={customers}
            onCustomerSelect={cust => handleSelectCustomer(cust, false)} // First debtor
            nextButtonText={currentProduct.numBookings === 1 ? 'Submit for Client Approval' : 'Proceed and Select Debtor 2'}
          />
        )}
        {currentStep === 2 && (
          <SelectCustomer
            selectedCustomer={secondCustomer}
            customers={customers.filter(({ id }) => id !== customer?.id)}
            onCustomerSelect={cust => handleSelectCustomer(cust, true)} // Second debtor
            nextButtonText="Submit for Client Approval"
          />
        )}
        {currentStep === 3 && (
          <ApprovalRequestSent
            nextStep={nextStep}
          />
        )}
      </div>
    </Modal>
  );
};

export default CreditReportModal;
