import { FC, Fragment } from 'react';

const RenderSpotifyPlayer: FC<{ trackId: string | undefined }> = ({ trackId }) => (
  <Fragment>
    {trackId && <div key={trackId}>
      <iframe
        style={{ borderRadius: "12px" }}
        src={`https://open.spotify.com/embed/track/${trackId}?utm_source=generator`}
        title='Spotify audio player'
        width="100%"
        height="152"
        frameBorder="0"
        allowFullScreen={false}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    </div>}
  </Fragment>
);

export default RenderSpotifyPlayer;