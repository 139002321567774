import { useContext, useEffect, useState } from 'react';
import { Creator, CreditReport, MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import CreditReportIcon from '@components/Icons/CreditReport';
import CheckCircle from '@components/Icons/CheckCircle';
import Buttons from '@components/Buttons';
import { DashboardContext } from '@layouts/DashboardLayout';
import { useOpenCreditReportPane } from '@panes/hooks';
import SkipTaskConfirmationModal from '@modals/SkipTaskConfirmationModal';
import CreditReportModal from '@modals/CreditReportModal';
import CreditReportApprovalModal from '@modals/CreditReportModal/CreditReportApproval';
import { useUser } from '@/providers/Auth';
import s from './CreditReportCard.module.scss';

type Props = {
  creator: Pick<Creator, 'slug'>;
  request: Pick<CreditReport, 'id' | 'completedAt' | 'skippedAt' | 'status'>
    & { responses: { creditReportResponseId: string; personId: string; isCompleted: boolean }[] };
  canClick: boolean;
  canSkip: boolean;
  setIsFulfilled?: (b: boolean) => void;
  setIsSkipped?: (b: boolean) => void;
  onChange?: () => Promise<void>;
  completedAt?: string | null;
  integrationSlugs?: string[];
  userWorkflowId?: string;
  assigneeIds?: string[];
};

const CreditReportCard: React.FC<Props> = ({
  creator,
  userWorkflowId,
  setIsFulfilled,
  setIsSkipped,
  canClick,
  canSkip,
  request,
  assigneeIds = [],
  onChange,
}) => {
  const [user] = useUser();
  const [skipTaskModalOpen, setSkipTaskModalOpen] = useState(false);
  const [isCreditReportModalOpen, setCreditReportModalOpen] = useState(false);
  const [isCreditReportApprovalModalOpen, setCreditReportApprovalModalOpen] = useState(false);
  const { isInDashboard } = useContext(DashboardContext);
  const openCreditReportPane = useOpenCreditReportPane();

  const { getData: updateCreditReport } = useNoodleApi(tsClient.creditReportsB2C.updateCreditReport, {
    toastOnError: true,
  });

  const response = request.responses?.find(({ personId }) => personId === user?.id);
  const isCompleted = !!request.completedAt || !!response?.isCompleted;
  const isSkipped = !!request.skippedAt;

  useEffect(() => {
    setIsFulfilled?.(isCompleted);
  }, [isCompleted, setIsFulfilled]);

  useEffect(() => {
    setIsSkipped?.(isSkipped);
  }, [isSkipped, setIsSkipped]);

  const handleClick = (): void => {
    /* Open pane if;
     * - credit report is completed
     * - the user is one of debtors and he already purchased the credit report
     * - the user is attorney (credit report has responses but none for current user)
     */
    if (request.status === 'completed' || response?.isCompleted || (request.responses.length && !response)) {
      openCreditReportPane({ creditReportId: request.id });
    } else if (!isSkipped) {
      if (request.status === 'approval-request-sent') {
        // allow only assignees can proceed purchasing flow
        if (assigneeIds.includes(user?.id ?? '')) {
          setCreditReportApprovalModalOpen(true);
        }
      } else {
        setCreditReportModalOpen(true);
      }
    }
  };

  const handleSkipTask = async (): Promise<void> => {
    await updateCreditReport({
      creditReportId: request.id,
      status: 'skipped',
    });
    setSkipTaskModalOpen(false);
    if (onChange) onChange();
  };

  const handleConfirmTask = async (): Promise<void> => {
    setCreditReportModalOpen(false);
    setCreditReportApprovalModalOpen(false);
    if (onChange) onChange();
  };

  return (
    <>
      {skipTaskModalOpen && (
        <SkipTaskConfirmationModal
          onClose={() => setSkipTaskModalOpen(false)}
          onConfirm={handleSkipTask}
          taskType={MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT}
        />
      )}
      {isCreditReportModalOpen && (
        <CreditReportModal
          creator={creator}
          creditReportId={request.id}
          userWorkflowId={userWorkflowId}
          onConfirm={handleConfirmTask}
          onClose={() => setCreditReportModalOpen(false)}
        />
      )}
      {isCreditReportApprovalModalOpen && (
        <CreditReportApprovalModal
          creditReportId={request.id}
          onConfirm={handleConfirmTask}
          onClose={() => setCreditReportApprovalModalOpen(false)}
        />
      )}
      <Buttons
        key={request.id}
        className={s.container}
        isWrapper
        isFullWidth
        disabled={!canClick || (isSkipped && !canSkip)}
        style={isSkipped ? { backgroundColor: 'var(--color-gray-25)', borderRadius: 8 } : {}}
        onClick={handleClick}
      >
        <figure style={(isCompleted && { backgroundColor: 'var(--color-primary)', borderColor: 'var(--color-primary)' }) || {}}>
          {isCompleted && <CreditReportIcon weight="fill" size={24} color="var(--color-gray-0)" />}
          {!isCompleted && isSkipped && <CreditReportIcon size={24} color="var(--color-gray-100)" />}
          {!isCompleted && !isSkipped && <CreditReportIcon size={24} color="var(--color-primary)" />}
          {isCompleted && (
            <div className={s.complete}>
              <CheckCircle size={16} weight="fill" color="var(--color-success)" />
            </div>
          )}
        </figure>
        <div>
          <div className={s.type}>
            <p className="body-sm-bold">Get Credit Report</p>
          </div>

          {isSkipped && <p className="caption">This task is no longer required.</p>}
        </div>
        {canClick
        && !isSkipped && (
          <div
            className={s.open}
            {...(!isCompleted
              && !isInDashboard && {
              style: {
                backgroundColor: 'var(--color-primary)',
                border: '1px solid var(--color-primary)',
                color: 'var(--color-gray-0)',
              },
            })}
          >
            View
          </div>
        )}
      </Buttons>
      {canSkip && !isSkipped && (
        <Buttons
          onClick={event => {
            event.stopPropagation();
            setSkipTaskModalOpen(true);
          }}
          className={s.skip}
        >
          Skip
        </Buttons>
      )}
    </>
  );
};

export default CreditReportCard;
