import VimeoPlayer, { vimeoUriToId } from "@components/VimeoPlayer";

type RenderVimeoVideoPropsType = {
  vimeoUri: string,
  isExtraSmall?: boolean,
  downloadUrl?: string | null;
  contentType?: string | null;
  isMediaAttachment?: boolean;
  isBroadcast?: boolean;
};

const RenderVimeoVideo: React.FC<RenderVimeoVideoPropsType> = ({
  vimeoUri,
  isExtraSmall = false,
  downloadUrl,
  contentType,
  isMediaAttachment,
  isBroadcast,
}) => (
  <VimeoPlayer
    contentType={contentType}
    downloadUrl={downloadUrl}
    id={vimeoUri}
    videoId={vimeoUriToId(vimeoUri)}
    isExtraSmall={isExtraSmall}
    isMediaAttachment={isMediaAttachment}
    isBroadcast={isBroadcast}
  />
);

export default RenderVimeoVideo;
