import { ReactElement, useEffect, useRef, useState } from 'react';
import { useToast } from '@hooks';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Modal from '@components/Modal';
import ProgressIndicator from '@components/ProgressIndicator';
import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import { useUserProfile } from '@/providers/Auth';
import { CREDIT_REPORT_KEY, CREDIT_REPORT_HOST_SUBDOMAIN } from '@/configuration/client';
import Confirm from './Confirm';
import s from './CreditReportModal.module.scss';

const steps = [
  { content: '', title: 'Enrollment' },
  { content: '', title: '' },
];

type Props = {
  creditReportId: string;
  onClose: () => void;
  onConfirm: () => unknown;
};

const CreditReportApprovalModal = ({ creditReportId, onConfirm, onClose }: Props): ReactElement => {
  const profile = useUserProfile();
  const [currentStep, setCurrentStep] = useState(0);
  const modalRef = useRef<HTMLDivElement>(null);

  const { fetchingState: pullingCreditReportData, getData: pullCreditReportDataFn } = useNoodleApi(tsClient.creditReportsB2C.pullCreditReportData, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error pulling credit report data. Please try again.',
    ],
    toastOnError: true,
  });

  const {
    fetchingState: generatingPreauthToken,
    getData: getPreauthTokenFn,
    data: preauthToken,
  } = useNoodleApi(tsClient.creditReportsB2C.getPreauthToken, {
    toastErrorMessage: error => [
      useToast.ToastTypeVariants.ERROR,
      error?.errors?.[0] || 'There was an error generating preauth token. Please try again.',
    ],
    toastOnError: true,
  });

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const nextStep = (): void => {
    if (currentStep === steps.length - 1) {
      onConfirm();
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!creditReportId) {
      return;
    }

    await pullCreditReportDataFn({
      creditReportId,
    });

    nextStep();
  };

  useEffect(() => {
    if (currentStep === 0 && profile?.email) {
      const userInfo = {
        email: profile.email,
      };

      getPreauthTokenFn({
        creditReportId,
        userInfo,
      });
    }
  }, [currentStep, profile?.email, creditReportId, getPreauthTokenFn]);

  useEffect(() => {
    // Listen for messages from the widget about enrollment success or requests for secondary debtor info
    const handleMessage = (event: { data: { type: string } }): void => {
      if (event.data.type === 'USER_ENROLLED') {
        handleSubmit();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <Modal
      hideBottomInset
      title={steps[currentStep].title}
      onClose={pullingCreditReportData.isFetching || generatingPreauthToken.isFetching || currentStep === steps.length - 1 ? undefined : onClose}
      ref={modalRef}
      closeIcon={<ArrowCircleLeft weight="fill" size={24} color="var(--color-gray-100)" />}
    >
      <div className={s.content}>
        {currentStep === 0 && (
          <>
            {pullingCreditReportData.isFetching && <ProgressIndicator isCentered />}

            {!pullingCreditReportData.isFetching && preauthToken?.token && (
              <iframe
                id="sc-iframe"
                src={`https://${CREDIT_REPORT_HOST_SUBDOMAIN}.stitchcredit.com/login-direct?key=${CREDIT_REPORT_KEY}&token=${preauthToken.token}&ocf=536872947&oct=EF4D7E`}
                width="100%"
                height="800px"
                frameBorder="0"
              />
            )}
          </>
        )}

        {currentStep === 1 && <Confirm nextStep={nextStep} />}
      </div>
    </Modal>
  );
};

export default CreditReportApprovalModal;
