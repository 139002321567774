import { FC } from 'react';
import MimeTypes from '@typings/MimeTypes';
import File from '@components/Icons/File';
import FilePdf from '@components/Icons/FilePdf';
import FileCsv from '@components/Icons/FileCsv';
import FileZip from '@components/Icons/FileZip';
import FileDoc from '@components/Icons/FileDoc';
import FileText from '@components/Icons/FileText';
import FilePpt from '@components/Icons/FilePpt';
import FileXls from '@components/Icons/FileXls';
import { Media } from '@typings/graphql-models';

import s from './RenderFile.module.scss';

type Props = {
  file: Pick<Media, 'id' | 'url' | 'type'> & {
    name?: string;
  };
};

const RenderFile: FC<Props> = ({ file }) => {
  const download = async (url: string, name: string): Promise<void> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;
    a.style.display = 'none';

    if (name) a.download = name;
    document.body.appendChild(a);
    a.click();
  };

  const isPdf = file.type === MimeTypes.PDF;
  const isCsv = file.type === MimeTypes.CSV;
  const isZip = file.type === MimeTypes.ZIP;
  const isDoc = file.type === MimeTypes.DOC || file.type === MimeTypes.DOCX;
  const isTxt = file.type === MimeTypes.TXT;
  const isPpt = file.type === MimeTypes.PPT || file.type === MimeTypes.PPTX;
  const isXls = file.type === MimeTypes.XLS || file.type === MimeTypes.XLSX;

  return (
    <button className={s.file} onClick={() => download(file.url || '', file.name || '')}>
      {
        (isPdf && <FilePdf weight="fill" size={24} color="var(--color-primary)" />)
        || (isCsv && <FileCsv weight="fill" size={24} color="var(--color-primary)" />)
        || (isZip && <FileZip weight="fill" size={24} color="var(--color-primary)" />)
        || (isDoc && <FileDoc weight="fill" size={24} color="var(--color-primary)" />)
        || (isTxt && <FileText weight="fill" size={24} color="var(--color-primary)" />)
        || (isPpt && <FilePpt weight="fill" size={24} color="var(--color-primary)" />)
        || (isXls && <FileXls weight="fill" size={24} color="var(--color-primary)" />)
        || <File weight="fill" size={24} color="var(--color-primary)" />
      }
      <div>
        <p>{file.name}</p>
        <small>Click to download</small>
      </div>
    </button>
  );
};

export default RenderFile;
