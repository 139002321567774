import { ReactElement } from 'react';
import Button from '@components/DesignLibrary/Button';
import CheckCircle from '@components/Icons/CheckCircle';
import Spacer from '@components/Spacer';
import s from './CreditReportModal.module.scss';

type Props = {
  nextStep: () => void;
};

const ApprovalRequestSent = ({ nextStep }: Props): ReactElement => (
  <div className={s.confirm}>
    <CheckCircle size="48" color="var(--color-success)" weight="fill" />

    <Spacer size={8} />

    <div className={s.approvalRequestSentText}>An approval request has been sent to your client to pull their credit report.</div>

    <Spacer size={24} />

    <Button variant="primary" size="md" fullWidth onClick={nextStep}>
      Done
    </Button>
  </div>
);

export default ApprovalRequestSent;
