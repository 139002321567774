import { isBrowser } from 'helpers/helper';
import React, { Fragment, lazy, ReactElement, Suspense, FC } from 'react';

import AudioPlayerTemplate, { AudioTemplateProps } from './AudioPlayerTemplate';
import { AudioProps } from './AudioPlayer';

const AudioPlayer = lazy(() => import('./AudioPlayer'));

const Audio:FC<AudioTemplateProps & AudioProps> = (props):ReactElement => (
  <Fragment>
    {isBrowser()
      ? (
        <Suspense fallback={<AudioPlayerTemplate />}>
          <AudioPlayer {...props} />
        </Suspense>
      )
      : (
        <AudioPlayerTemplate />
      )}
  </Fragment>
);

export default Audio;
