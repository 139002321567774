import { FC, useEffect, useState } from 'react';
import { Media } from '@typings/graphql-models';
import useNoodleApi from '@hooks/useNoodleApi';
import { getMediaData } from '@tsClient';
import MediaLoader from '@components/MediaLoader';
import AudioPlayer from '@components/AudioPlayer';
import getMediaUrl from '@helpers/getMediaUrl';

type Props = {
  media: Pick<Media, 'id' | 'url' | 's3OriginId' | 'transloaditS3Id'>;
};

const MediaAudioPlayer: FC<Props> = ({ media: originalMedia }) => {
  const [media, setMedia] = useState(originalMedia);
  const { getData: getMedia } = useNoodleApi(getMediaData);
  useEffect(() => {
    const refetch = async (): Promise<void> => {
      const { data: newMedia } = await getMedia({ id: media.id });
      if (newMedia?.transloaditS3Id) {
        setMedia(newMedia);
      } else {
        setTimeout(refetch, 10000);
      }
    };
    if (!media.transloaditS3Id) {
      refetch();
    }
  }, [getMedia, media]);
  if (!media.transloaditS3Id) {
    return (
      <MediaLoader
        isSmall
        isProcessMessage={true}
        message='Loading audio...'
        style={{ maxHeight: 54 }}
      />
    );
  }
  return <AudioPlayer audio={{ url: getMediaUrl(media) }} />;
};

export default MediaAudioPlayer;
