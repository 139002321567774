import classNames from 'classnames';
import { CSSProperties } from 'react';
import renderParticipantsNames from '@helpers/renderParticipantsNames';
import UserImage from '@components/UserImage';
import { useIsMobile } from '@hooks';
import Tooltip from '@components/Tooltip';
import s from './CommentParticipants.module.scss';

type ParticipantType = {
  id: string;
  name?: string | null;
  logo?: string | null;
  color?: string | null;
};

type PropsType = {
  participants?: Array<ParticipantType> | null;
  userLogoSize: number;
  maxLength?: number;
  noLabel?: boolean;
  style?: CSSProperties;
  blackBorder?: boolean;
  remainingParticipantsAsNumber?: boolean;
  totalParticipants?: number;
  isJoin?: boolean;
  participantsToHighlight?: Array<string>;
  showNames?: boolean;
  areAssignees?: boolean;
};

const CommentParticipants: React.FC<PropsType> = ({
  participants,
  userLogoSize,
  maxLength = 3,
  noLabel,
  style,
  blackBorder,
  remainingParticipantsAsNumber,
  totalParticipants,
  isJoin,
  participantsToHighlight,
  showNames,
  areAssignees,
}) => {
  const isMobile = useIsMobile();
  if (!participants || participants.length === 0) {
    return null;
  }

  const iconsToDisplay = isMobile && maxLength >= 3 ? 3 : maxLength;

  const participantsToHighlightData = participants.filter(participant => participantsToHighlight?.find(p => p === participant.id));
  const participantsWithLogo = participants.filter(participant => participant.logo);
  const participantsWithoutLogo = participants.filter(participant => !participant.logo);
  const regularParticipants = [...participantsWithLogo, ...participantsWithoutLogo].filter(
    participant => !participantsToHighlight?.find(p => p === participant.id),
  );
  const filteredParticipants = [...participantsToHighlightData, ...regularParticipants];

  return (
    <div className={s.participants}>
      <div className={s.participants__photos}>
        {filteredParticipants
          .map((participant, idx) => {
            const isHighlighted = participantsToHighlight?.find(p => p === participant.id);
            return (
              <div
                style={{
                  minWidth: userLogoSize,
                  ...(isHighlighted && {
                    borderRadius: '50%',
                    boxShadow: '0px 0px 0px 2px var(--color-gray-0), 0px 0px 0px 4px var(--color-warning)',
                  }),
                }}
                key={`${participant.id}-${idx}`}
              >
                {showNames
                  ? (
                    <>
                      {participant.name && (
                        <Tooltip text={participant.name} className={s.tooltip}>
                          <UserImage
                            color={participant.color}
                            url={participant.logo}
                            size={userLogoSize}
                            hasBorder={!isHighlighted}
                            borderColor={(blackBorder && 'var(--color-gray-100)') || undefined}
                            key={participant.id}
                            name={participant.name || ''}
                          />
                        </Tooltip>
                      )}
                    </>
                  )
                  : (
                    <UserImage
                      color={participant.color}
                      url={participant.logo}
                      size={userLogoSize}
                      hasBorder={!isHighlighted}
                      borderColor={(blackBorder && 'var(--color-gray-100)') || undefined}
                      key={participant.id}
                      name={participant.name || ''}
                    />
                  )}
              </div>
            );
          })
          .splice(0, iconsToDisplay)}
        {remainingParticipantsAsNumber && filteredParticipants.length > iconsToDisplay && (
          <div className={s.participants__remaining} style={{ minHeight: userLogoSize, minWidth: userLogoSize }}>
            +{filteredParticipants.length - iconsToDisplay}
          </div>
        )}
      </div>
      {!noLabel && (
        <p className={classNames('caption', s.participants__text)} style={style}>
          {renderParticipantsNames(filteredParticipants, totalParticipants, iconsToDisplay, isJoin, areAssignees)}
        </p>
      )}
    </div>
  );
};

export default CommentParticipants;
