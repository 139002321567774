import React, { FC, useEffect } from 'react';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import Buttons from '@components/Buttons';
import { useRouter } from 'next/router';
import { Creator, Price, Product } from '@typings/api-models';
import { Asset, ProductType } from '@typings/graphql-models';
import CurrencyCircleDollar from '@components/Icons/CurrencyCircleDollar';
import CheckCircle from '@components/Icons/CheckCircle';
import * as format from '@format';
import s from './PaymentLinkButton.module.scss';

type Props = {
  data: {
    price?: Pick<Price, 'id' | 'priceTitle'> & {
      product?: Pick<Product, 'id' | 'slug' | 'isActive' | 'title'> & {
        productTypes?: Pick<ProductType, 'title' | 'noodleProductType'>[];
        creator?: Pick<Creator, 'id' | 'slug' | 'name'> & {
          image?: Pick<Asset, 'url'> | null;
        };
      };
    };
    workflowReferenceId?: string;
    // TODO: receive the isCompleted from the backend
    isCompleted?: boolean;
  };
  canClick: boolean;
  setIsFulfilled?: (f: boolean) => void;
  completedAt: string | null;
};

const PaymentLinkButton: FC<Props> = ({ setIsFulfilled, canClick, data, completedAt }) => {
  const router = useRouter();

  const creatorSlug = data.price?.product?.creator?.slug;
  const productSlug = data.price?.product?.slug;
  const priceId = data.price?.id;
  const title = data.price?.priceTitle || data.price?.product?.title;

  useEffect(() => {
    if (data) {
      setIsFulfilled?.(data.isCompleted ?? false);
    }
  }, [data, setIsFulfilled]);

  if (!creatorSlug || !productSlug || !priceId) {
    return null;
  }

  return (
    <Buttons
      className={s.container}
      disabled={!canClick}
      isFullWidth
      isWrapper
      onClick={() => {
        router.push(
          getUrl(IDENTIFIERS.PRODUCT_PURCHASE, {
            creatorSlug,
            priceId,
            productSlug,
            workflowReferenceId: data.workflowReferenceId,
          }),
        );
      }}
    >
      <figure style={(data.isCompleted && { backgroundColor: 'var(--color-primary)', borderColor: 'var(--color-primary)' }) || {}}>
        <CurrencyCircleDollar weight="fill" color={data.isCompleted ? "var(--color-gray-0)" : 'var(--color-primary)'} size={24} />
        {data.isCompleted && (
          <div className={s.complete}>
            <CheckCircle size={16} weight="fill" color="var(--color-success)" />
          </div>
        )}
      </figure>
      <div><p className='caption'>Payment request{completedAt && ` completed on ${format.datetime.withoutTimezone({datetime:completedAt})}`}</p><p className="body-sm-bold">Buy {title}</p></div>
      {canClick && <div className={s.open} {...!data.isCompleted && {
        style: {
          backgroundColor: 'var(--color-primary)',
          border: '1px solid var(--color-primary)',
          color: 'var(--color-gray-0)',
        },
      }}>View</div>}
    </Buttons>
  );
};

export default PaymentLinkButton;
