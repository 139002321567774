import React, { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import Buttons from '@components/Buttons';
import HandCoins from '@components/Icons/HandCoins';
import CheckCircle from '@components/Icons/CheckCircle';
import XCircle from '@components/Icons/XCircle';
import Clock from '@components/Icons/Clock';
import { useRouter } from 'next/router';
import { Invoice, INVOICE_STATUS, MESSAGE_ATTACHMENT_REFERENCE_TYPES } from '@typings/api-models';
import AttachPriceToInvoiceModal from '@modals/AttachPriceToInvoiceModal';
import SkipTaskConfirmationModal from '@modals/SkipTaskConfirmationModal';
import updateInvoice from '@tsClient/invoices/updateInvoice';
import { DashboardContext } from '@layouts/DashboardLayout';
import InvoiceProgressTooltip from '@components/DesignLibrary/InvoiceProgressTooltip';
import { useOpenInvoicePane } from '@panes/hooks';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import * as format from '@format';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { m } from 'framer-motion';
import s from './InvoiceCard.module.scss';

type Props = {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'status'
    | 'fallbackTitle'
    | 'personId'
    | 'productId'
    | 'title'
    | 'amountPaid'
    | 'amountTotal'
    | 'amountPending'
    | 'amountDue'
    | 'amountRefunded'
    | 'currency'
    | 'isPartialPaymentEnabled'
  >;
  canClick: boolean;
  canSkip: boolean;
  creatorSlug: string;
  reload?: () => Promise<void>;
  setIsFulfilled?: (f: boolean) => void;
  setIsFailed?: (f: boolean) => void;
  setIsSkipped?: (f: boolean) => void;
  completedAt?: string | null;
};

const InvoiceCard: FC<Props> = ({ setIsFulfilled, setIsSkipped, setIsFailed, invoice, canClick, canSkip, creatorSlug, reload, completedAt }) => {
  const router = useRouter();
  const { isInDashboard } = useContext(DashboardContext);
  const { isInUserProfile } = useContext(UserProfileContext);
  const { data: invoicePaymentPlan, getData: getPaymentPlan } = useNoodleApi(tsClient.invoices.getPaymentPlan);

  const openInvoicePane = useOpenInvoicePane();

  const [isModalOpen, setModalOpen] = useState(false);
  const [skipTaskModalOpen, setSkipTaskModalOpen] = useState(false);

  const isPaid = invoice.status === INVOICE_STATUS.PAID;
  const isSkipped = invoice.status === INVOICE_STATUS.SKIPPED;
  const isInitiated = invoice.status === INVOICE_STATUS.FINAL_PAYMENT_INITIATED || invoice.status === INVOICE_STATUS.PAYMENT_REQUESTED;
  const isGenerating = invoice.status === INVOICE_STATUS.IS_GENERATING;
  const isFailed = invoice.status === INVOICE_STATUS.PAYMENT_FAILED;
  const isVoided = invoice.status === INVOICE_STATUS.VOIDED;
  const isRefunded = invoice.amountPaid === 0 && invoice.amountPending === 0 && invoice.amountRefunded > 0;
  const isDisabled = isSkipped || isVoided;

  useEffect(() => {
    if (!isInitiated) {
      getPaymentPlan({ id: invoice.id });
    }
  }, [getPaymentPlan, invoice, isInitiated]);

  useEffect(() => {
    setIsFulfilled?.(isPaid || isInitiated);
  }, [isPaid, isInitiated, setIsFulfilled]);

  useEffect(() => {
    setIsFailed?.(isFailed);
  }, [isFailed, setIsFailed]);

  useEffect(() => {
    setIsSkipped?.(isSkipped);
  }, [isSkipped, setIsSkipped]);

  const title = invoice.title || invoice.fallbackTitle;

  const handleClick = (): void => {
    if (isInDashboard) {
      if (isGenerating) {
        setModalOpen(true);
      } else {
        openInvoicePane({ invoiceId: invoice.id });
      }
    } else if (isInUserProfile) {
      openInvoicePane({ invoiceId: invoice.id });
    } else {
      router.push(
        getUrl(IDENTIFIERS.INVOICE_DETAIL, {
          creatorSlug,
          invoiceId: invoice.id,
        }),
      );
    }
  };

  const getGeneratingTitleText = (): string => {
    let fallbackTitleText = '';
    if (invoice.title || invoice.fallbackTitle) {
      fallbackTitleText = ` ${invoice.title || invoice.fallbackTitle}`;
    }
    if (isInUserProfile) {
      return `Your invoice is being generated`;
    }
    return `Generate${fallbackTitleText} invoice`;
  };

  const handleSkipTask = async (): Promise<void> => {
    await updateInvoice({
      id: invoice.id,
      status: INVOICE_STATUS.SKIPPED,
    });
    setSkipTaskModalOpen(false);
    if (reload) reload();
  };

  return (
    <>
      {skipTaskModalOpen && (
        <SkipTaskConfirmationModal
          onClose={() => setSkipTaskModalOpen(false)}
          onConfirm={handleSkipTask}
          taskType={MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE}
        />
      )}
      <Buttons
        className={s.container}
        isFullWidth
        isWrapper
        onClick={handleClick}
        disabled={!canClick || isDisabled}
        style={isDisabled ? { backgroundColor: 'var(--color-gray-25)', borderRadius: 8 } : {}}
      >
        <figure style={(isPaid && { backgroundColor: 'var(--color-primary)', borderColor: 'var(--color-primary)' }) || {}}>
          <HandCoins weight="fill" color={isPaid ? 'var(--color-gray-0)' : 'var(--color-primary)'} size={24} />
          {(isPaid || isInitiated || isGenerating || isFailed) && (
            <div className={s.complete}>
              {isPaid && <CheckCircle size={16} weight="fill" color="var(--color-success)" />}
              {isFailed && <XCircle size={16} weight="fill" color="var(--color-error)" />}
              {(isInitiated || isGenerating) && <Clock size={16} weight="fill" color="var(--color-gray-100)" />}
            </div>
          )}
        </figure>
        {isGenerating
          ? (
            <div className={classNames(s.isGenerating, s.paymentText)}>
              {isInUserProfile && <p className="caption">Invoice to pay</p>}
              <p className="body-sm-bold">{getGeneratingTitleText()}</p>
              {canClick && !isInUserProfile && <p className="caption">Click here to generate</p>}
            </div>
          )
          : (
            <div className={s.paymentText}>
              {!isInitiated && (
                <p className="caption">
                  {isInUserProfile ? 'Invoice to pay' : 'Invoice request'}{completedAt && ` completed on ${format.datetime.withoutTimezone({datetime:completedAt})}`}
                  {invoicePaymentPlan && invoicePaymentPlan?.nextPaymentDate && (
                    <m.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                      {' '}
                    · Next payment on {format.datetime.monthDay(invoicePaymentPlan.nextPaymentDate)}
                    </m.span>
                  )}
                </p>
              )}
              <p className="body-sm-bold">{title}</p>
              {canClick && <>
                {invoice.isPartialPaymentEnabled
                  ? <InvoiceProgressTooltip invoice={invoice} />
                  : (
                    <p className="caption">Total amount: {format.price.withCurrency(invoice.amountTotal, invoice.currency)}</p>
                  )}
              </>}
              {isInitiated && !invoice.isPartialPaymentEnabled && <p className="caption">This payment is processing.</p>}
              {isFailed && <p className="caption" style={{ color: 'var(--color-error)' }}>Payment failed.</p>}
            </div>
          )}
        {isVoided && (
          <p className="caption" style={{ color: 'var(--color-error)' }}>
            This invoice has been voided.
          </p>
        )}
        {isRefunded && (
          <p className="caption" style={{ color: 'var(--color-error)' }}>
            This invoice has been refunded.
          </p>
        )}
        {isSkipped && <p className="caption">This task is no longer required.</p>}
        {canClick && !isDisabled && (
          <div
            className={s.open}
            {...(((!isPaid && isInUserProfile && !isInitiated && !invoice.isPartialPaymentEnabled)
              || (isInUserProfile && invoice.isPartialPaymentEnabled && !isPaid)) && {
              style: {
                backgroundColor: 'var(--color-primary)',
                border: '1px solid var(--color-primary)',
                color: 'var(--color-gray-0)',
                width: '120px',
              },
            })}
          >
            {((!isPaid && isInUserProfile && !isInitiated && !invoice.isPartialPaymentEnabled)
              || (isInUserProfile && invoice.isPartialPaymentEnabled && !isPaid)) && 'Make a payment' || 'View'}
          </div>
        )}
      </Buttons>
      {canSkip && !isDisabled && (
        <Buttons
          onClick={event => {
            event.stopPropagation();
            setSkipTaskModalOpen(true);
          }}
          className={s.skip}
        >
          Skip
        </Buttons>
      )}
      {isModalOpen && !isDisabled && (
        <AttachPriceToInvoiceModal
          invoiceId={invoice.id}
          onSubmitCompleted={async () => {
            if (reload) {
              await reload();
            }
            setModalOpen(false);
          }}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default InvoiceCard;
