import { useContext } from 'react';
import {
  FormStatus,
  INVOICE_STATUS,
  MESSAGE_ATTACHMENT_REFERENCE_TYPES,
  MessageAttachment,
  MessageAttachmentWithTypedData,
} from '@typings/api-models';
import UserDocumentRequestCard from '@components/UserDocumentRequestCard';
import FormRequestCard from '@components/FormRequestCard';
import CreditReportCard from '@components/CreditReportCard';
import PaymentLinkButton from '@components/PaymentLinkButton';
import InvoiceCard from '@components/InvoiceCard';
import { DashboardContext } from '@layouts/DashboardLayout';
import CustomTermsCard from '@components/CustomTermsCard';
import BookingAttachmentCard from '@components/BookingAttachmentCard';
import TeamsContext from '@providers/Teams/TeamsContext';

type Props = {
  creator: Parameters<typeof FormRequestCard>[0]['creator'];
  attachment: Pick<MessageAttachment, 'id' | 'referenceId' | 'canAccess' | 'completedAt' | 'integrationSlugs'> &
    MessageAttachmentWithTypedData & {
      assigneeIds: string[];
    };
  isWorkflowAttachment?: boolean;
  userWorkflowId?: string;
  reload?: () => Promise<void>;
  setIsFulfilled?: (f: boolean) => void;
  setIsFailed?: (f: boolean) => void;
  setIsSkipped?: (f: boolean) => void;
  isInChat?: boolean;
  overrideIsAssigned?: (f: boolean) => void;
};

const CommentAttachment: React.FC<Props> = ({
  attachment,
  creator,
  isWorkflowAttachment,
  userWorkflowId,
  reload,
  setIsFulfilled,
  setIsFailed,
  setIsSkipped,
  isInChat,
  overrideIsAssigned,
}) => {
  const { isInDashboard } = useContext(DashboardContext);
  const { currentTeamOwner } = useContext(TeamsContext);

  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.DOCUMENT_REQUEST_USER) {
    return (
      <UserDocumentRequestCard
        setIsFulfilled={setIsFulfilled}
        creatorSlug={creator.slug}
        canClick={!isWorkflowAttachment || attachment.canAccess}
        request={attachment.data}
        completedAt={attachment.completedAt}
      />
    );
  }
  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.PAYMENT_LINK) {
    return (
      <PaymentLinkButton
        setIsFulfilled={setIsFulfilled}
        canClick={!isWorkflowAttachment || attachment.canAccess}
        data={attachment.data}
        completedAt={attachment.completedAt}
      />
    );
  }
  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST) {
    const { data } = attachment;
    return (
      <FormRequestCard
        setIsFulfilled={setIsFulfilled}
        setIsSkipped={setIsSkipped}
        onChange={reload}
        canClick={!isWorkflowAttachment || attachment.canAccess}
        canSkip={creator.slug === currentTeamOwner?.slug && !isInChat && data.status !== FormStatus.COMPLETED}
        creator={creator}
        request={{
          ...data,
          person: { id: data.personId },
        }}
        completedAt={attachment.completedAt}
        integrationSlugs={attachment.integrationSlugs}
      />
    );
  }
  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CREDIT_REPORT) {
    const { data } = attachment;
    return (
      <CreditReportCard
        setIsFulfilled={setIsFulfilled}
        setIsSkipped={setIsSkipped}
        onChange={reload}
        userWorkflowId={userWorkflowId}
        canClick={attachment.canAccess}
        canSkip={creator.slug === currentTeamOwner?.slug && !isInChat && !data.completedAt}
        creator={creator}
        request={data}
        assigneeIds={attachment.assigneeIds}
      />
    );
  }
  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.INVOICE) {
    return (
      <InvoiceCard
        setIsFulfilled={setIsFulfilled}
        setIsFailed={setIsFailed}
        setIsSkipped={setIsSkipped}
        canClick={attachment.canAccess}
        canSkip={creator.slug === currentTeamOwner?.slug && !isInChat && attachment.data.invoice.status !== INVOICE_STATUS.PAID}
        invoice={attachment.data.invoice}
        creatorSlug={creator.slug}
        reload={reload}
        completedAt={attachment.completedAt}
      />
    );
  }
  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.CUSTOM_TERMS) {
    return (
      <CustomTermsCard
        canClick={attachment.canAccess}
        canSkip={creator.slug === currentTeamOwner?.slug && !isInChat && !attachment.data.customTerms.agreedAt}
        setIsFulfilled={setIsFulfilled}
        setIsSkipped={setIsSkipped}
        onChange={reload}
        customTerms={attachment.data.customTerms}
        template={attachment.data.template}
        isInDashboard={isInDashboard}
      />
    );
  }
  if (attachment.referenceType === MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING) {
    return (
      <BookingAttachmentCard
        booking={attachment.data.booking}
        canSkip={
          creator.slug === currentTeamOwner?.slug
          && !isInChat
          && (!attachment.data.booking.startAt || new Date() < new Date(attachment.data.booking.startAt))
        }
        canClick={attachment.canAccess}
        reload={reload}
        setIsFulfilled={setIsFulfilled}
        overrideIsAssigned={overrideIsAssigned}
        isInDashboard={isInDashboard}
      />
    );
  }
  return null;
};

export default CommentAttachment;
