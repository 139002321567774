import getUserFirstAndLastName from '@helpers/getUserFirstAndLastName';

const renderParticipantsNames = (
  participants: { name?: string | null }[],
  total: number = participants.length,
  maxLength: number = 2,
  isJoin: boolean = false,
  areAssignees: boolean = false,
): string => {
  const namedParticipants = participants.filter(participant => participant.name);
  const participantsNames = namedParticipants.map(participant => {
    const name = getUserFirstAndLastName(participant.name);
    return `${name.firstName}${name.lastName ? ` ${name.lastName.charAt(0).toUpperCase()}.` : ''}`;
  });

  const isGreaterThanMaxLength = participantsNames.length > maxLength;
  const remainingCount = total - maxLength;
  const prefix = (isJoin && 'Join with ') || (areAssignees && 'Assigned to ') || '';
  const suffix = ((isJoin || areAssignees) && ` other${remainingCount === 1 ? '' : 's'}`) || 'others are discussing';

  if (isGreaterThanMaxLength) {
    const listedNames = participantsNames.slice(0, maxLength).join(', ');
    return `${prefix}${listedNames}, and ${remainingCount} ${suffix}`;
  }

  if (participantsNames.length === maxLength) {
    return `${prefix}${participantsNames.join(' and ')}${!(isJoin || areAssignees) && suffix || ""}`;
  }

  if (participantsNames.length > 0) {
    return `${prefix}${participantsNames.join(', ')}${!(isJoin || areAssignees) && suffix || ""}`;
  }

  return '';
};

export default renderParticipantsNames;
