import { ReactElement } from 'react';
import Button from '@/components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import s from './CreditReportModal.module.scss';

type Props = {
  title: string;
  description: string;
  buttonLabel: string;
  onClick: () => void;
};

const CreditFinancialReportCard = ({ title, description, buttonLabel, onClick }: Props): ReactElement => (
  <div className={s.reportCard}>
    <div>
      <p className="body-lg-bold">{title}</p>

      <Spacer size={8} />
      {/* eslint-disable-next-line risxss/catch-potential-xss-react */}
      <p className={s.reportCardDescription} dangerouslySetInnerHTML={{ __html: description }} />
    </div>

    <Button variant='primary' size='sm' onClick={onClick}>
      {buttonLabel}
    </Button>
  </div>
);

export default CreditFinancialReportCard;
