import { FC, Fragment } from 'react';

import s from './RenderYoutubeVideo.module.scss';

const RenderYoutubeVideo: FC<{ youtubeId: string | undefined }> = ({ youtubeId }) => (
  <Fragment>
    {youtubeId && <div key={youtubeId} className={s['youtube-container']}>
      <iframe
        width='100%'
        height='100%'
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>}
  </Fragment>
);

export default RenderYoutubeVideo;