import { ReactElement } from 'react';
import Button from '@components/DesignLibrary/Button';
import CheckCircle from '@components/Icons/CheckCircle';
import Spacer from '@components/Spacer';
import s from './CreditReportModal.module.scss';

const CONFIRM = {
  description: '',
  title: 'You\'re all set. Thank you.',
};

type Props = {
  nextStep: () => void;
};

const Confirm = ({ nextStep }: Props): ReactElement => (
  <div className={s.confirm}>
    <CheckCircle weight="fill" size="48" color="var(--color-success)" />

    <Spacer size={8} />

    <div className={s.confirmTitle}>{CONFIRM.title}</div>
    <Spacer size={8} />
    <div className="caption">{CONFIRM.description}</div>

    <Spacer size={24} />

    <Button variant="primary" size="md" fullWidth onClick={nextStep}>
      Done
    </Button>
  </div>
);

export default Confirm;
