import Modal from '@components/Modal';
import { FC } from 'react';
import AttachPriceToInvoice, { type AttachPriceToInvoiceProps } from '@lib/AttachPriceToInvoice';

type Props = AttachPriceToInvoiceProps & {
  onClose: () => void;
};

const AttachPriceToInvoiceModal: FC<Props> = ({ onClose, ...restProps }) => (
  <Modal
    hideBottomInset
    title='Generate Invoice'
    onClose={onClose}
  >
    <AttachPriceToInvoice {...restProps} />
  </Modal>
);

export default AttachPriceToInvoiceModal;
