import React, { FC, useContext, useEffect, useState } from 'react';
import * as format from '@format';
import Buttons from '@components/Buttons';
import Calendar from '@components/Icons/Calendar';
import CheckCircle from '@components/Icons/CheckCircle';
import * as ApiModels from '@typings/api-models';
import ScheduleBookingModal from '@modals/ScheduleBookingModal';
import { Clock } from '@phosphor-icons/react';
import SkipTaskConfirmationModal from '@modals/SkipTaskConfirmationModal';
import { MESSAGE_ATTACHMENT_REFERENCE_TYPES, Person } from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { UserProfileContext } from '@layouts/UserProfileLayout';
import SelectTeamMemberAvailabilityModal from '@/modals/SelectTeamMemberAvailabilityModal';
import s from './BookingAttachmentCard.module.scss';

type Props = {
  booking: Pick<ApiModels.Booking, 'endAt' | 'skippedAt' | 'startAt' | 'teamMemberPersonId'>
    & Parameters<typeof ScheduleBookingModal>[0]['booking']
    & { teamMemberPerson: Pick<Person, 'id' | 'name'> & {
      primaryColour?: { hex: string } | null;
      image?: { url: string } | null;
    } | null; };
  reload?: () => Promise<void>;
  setIsFulfilled?: (f: boolean) => void;
  canSkip?: boolean;
  overrideIsAssigned?: (f: boolean) => void;
  canClick: boolean;
  isInDashboard?: boolean;
};

const BookingAttachmentCard: FC<Props> = ({ booking, reload, setIsFulfilled, canSkip, overrideIsAssigned, canClick, isInDashboard }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [skipTaskModalOpen, setSkipTaskModalOpen] = useState(false);
  const [selectAvailabilityModalOpen, setSelectAvailabilityModalOpen] = useState(false);
  const { isInUserProfile } = useContext(UserProfileContext);

  const { getData: updateBooking } = useNoodleApi(tsClient.scheduling.updateBooking);

  const isCompleted = Boolean((booking.endAt && new Date(booking.endAt) < new Date()) || false);
  const isSkipped = Boolean(booking.skippedAt || false);
  const isScheduled = Boolean(booking.startAt || false) && !isSkipped && !isCompleted;

  const handleSkipTask = async (): Promise<void> => {
    await updateBooking({
      id: booking.id,
      updates: {
        skippedAt: new Date().toISOString(),
      },
    });
    setSkipTaskModalOpen(false);
    reload?.();
  };

  const handleSelectTeamMemberAvailability = async (teamMemberPersonId: string): Promise<void> => {
    await updateBooking({
      id: booking.id,
      updates: {
        teamMemberPersonId,
      },
    });
    setSelectAvailabilityModalOpen(false);
    reload?.();
  };

  const handleClick = (): void => {
    if (!isSkipped && !isCompleted) {
      setModalOpen(true);
    }
  };

  useEffect(() => {
    setIsFulfilled?.(isCompleted || isSkipped);
  }, [setIsFulfilled, isCompleted, isSkipped]);

  useEffect(() => {
    overrideIsAssigned?.(isScheduled);
  }, [overrideIsAssigned, isScheduled]);

  return (
    <>
      {isModalOpen && (
        <ScheduleBookingModal
          booking={booking}
          onClose={() => setModalOpen(false)}
          onSchedule={reload}
          teamMemberPersonId={booking.teamMemberPersonId ?? undefined}
        />
      )}
      {skipTaskModalOpen && (
        <SkipTaskConfirmationModal
          onClose={() => setSkipTaskModalOpen(false)}
          onConfirm={handleSkipTask}
          taskType={MESSAGE_ATTACHMENT_REFERENCE_TYPES.BOOKING}
        />
      )}
      {selectAvailabilityModalOpen && booking.productId && (
        <SelectTeamMemberAvailabilityModal
          onClose={() => setSelectAvailabilityModalOpen(false)}
          onSelect={handleSelectTeamMemberAvailability}
          productId={booking.productId}
          teamMemberPersonId={booking.teamMemberPersonId || null}
        />
      )}
      <Buttons
        className={s.container}
        isFullWidth
        isWrapper
        onClick={handleClick}
        disabled={!canClick || (isSkipped && !canSkip)}
        style={isSkipped ? { backgroundColor: 'var(--color-gray-25)', borderRadius: 8 } : {}}
      >
        <div className={s.contents}>
          <figure style={(isCompleted && { backgroundColor: 'var(--color-primary)', borderColor: 'var(--color-primary)' }) || {}}>
            {isCompleted ? <Calendar size={24} color="var(--color-gray-0)" weight='fill' /> : <Calendar size={24} color="var(--color-gray-100)" />}
            {isCompleted && (
              <div className={s.icon}>
                <CheckCircle size={16} weight="fill" color="var(--color-success)" />
              </div>
            )}
            {isScheduled && (
              <div className={s.icon}>
                <Clock size={16} weight='fill' color='var(--color-gray-75)' />
              </div>
            )}
          </figure>
          <div>
            <p className='caption'>Booking request{isCompleted && booking.endAt && ` completed on ${format.datetime.withoutTimezone({datetime:booking.endAt})}`}</p>
            <p className="body-sm-bold">{booking.price.priceTitle || booking.price.product.title || 'Booking'}</p>
            {!isCompleted && !isSkipped && <p className='caption'>{booking.startAt ? `Scheduled for ${format.datetime.withTimezone({ datetime: booking.startAt, timezone: booking.creator.timezone })}` : 'Select date and time'}</p>}
            {isSkipped && <p className='caption'>This booking was skipped.</p>}
          </div>
          {canClick && !isSkipped && (
            <div
              className={s.open}
              {...(!isCompleted && isInUserProfile && {
                style: {
                  backgroundColor: 'var(--color-primary)',
                  border: '1px solid var(--color-primary)',
                  color: 'var(--color-gray-0)',
                },
              })}
            >
              View
            </div>
          )}
        </div>
      </Buttons>
      {(!booking.startAt || canSkip) && !isSkipped && <div className={s.actions}>
        {isInDashboard && !booking.startAt && (
          <Buttons
            className={s.chooseAvailability}
            onClick={() => setSelectAvailabilityModalOpen(true)}
          >
            {booking.teamMemberPerson ? `${booking.teamMemberPerson.name}'s Availability` : 'Choose Availability'}
          </Buttons>
        )}
        {canSkip && (
          <Buttons
            onClick={(event) => {
              event.stopPropagation();
              setSkipTaskModalOpen(true);
            }}
            className={s.skip}
          >
            Skip
          </Buttons>
        )}
      </div >}
    </>
  );
};

export default BookingAttachmentCard;
