import { FC, Fragment, useMemo } from 'react';
import { Media } from '@typings/graphql-models';
import getMediaUrl from '@helpers/getMediaUrl';
import removeNullish from '@helpers/removeNullish';
import { YOUTUBE_ID_REGEX, SPOTIFY_ID_REGEX } from '@helpers/appConsts';
import MediaAudioPlayer from '@components/MediaAudioPlayer';
import ChatImage from '@components/ChatImage';
import { MessageType } from '@typings/api-models';
import RenderVimeoVideo from '../RenderVimeoVideo';
import RenderYoutubeVideo from '../RenderYoutubeVideo';
import RenderSpotifyPlayer from '../RenderSpotifyPlayer';
import RenderLoom from '../RenderLoomVideo';
import RenderFile from '../RenderFile';

type Props = {
  medias?: Pick<Media, 'id' | 'vimeoUri' | 'type' | 's3OriginId' | 'url'>[];
  isCreatorActivity?: boolean;
  commentType?: MessageType | null;
};

const RenderMediaAttachments: FC<Props> = ({ medias, isCreatorActivity, commentType }) => {
  const preparedMedias = useMemo(
    () =>
      medias
        ?.map(media => {
          if (
            media?.vimeoUri
            || media?.type?.includes('image')
            || media?.type?.includes('loom')
            || media?.type === 'youtube'
            || media?.type?.includes('spotify')
          ) {
            return media;
          }
          if (media?.type?.includes('audio')) {
            return { ...media, link: { url: getMediaUrl(media) } };
          }
          return { ...media, link: { url: getMediaUrl(media) } };
        })
        .filter(removeNullish),
    [medias],
  );

  const isBroadcast = commentType === MessageType.PaidBroadcast || commentType === MessageType.FreeBroadcast;

  return (
    <Fragment>
      {preparedMedias?.map(media => (
        <Fragment key={media.id}>
          {media?.vimeoUri && (
            <RenderVimeoVideo
              contentType={media?.type}
              downloadUrl={commentType === MessageType.Chat ? media?.url : null}
              vimeoUri={media?.vimeoUri}
              isExtraSmall={isCreatorActivity}
              isMediaAttachment
              isBroadcast={isBroadcast}
            />
          )
          || media?.type?.includes('audio') && <MediaAudioPlayer media={media} />
          || media?.type?.includes('image') && <ChatImage alt={'attachment'} url={getMediaUrl(media)} />
          || media?.type?.includes('loom') && media?.url && <RenderLoom url={media?.url} />
          || media?.type === 'youtube' && <RenderYoutubeVideo youtubeId={media?.url?.match(YOUTUBE_ID_REGEX)?.[1]} />
          || media?.type?.includes('spotify') && <RenderSpotifyPlayer trackId={media?.url?.match(SPOTIFY_ID_REGEX)?.[1]} />
          || (
            <RenderFile file={media} />
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default RenderMediaAttachments;
