import React, { FC, Fragment, useCallback } from 'react';
import { NoodleProductTypes } from '@typings/graphql-models';
import { nanoid } from 'nanoid';

import { SELF_ORIGIN } from '@configuration/client';
import removeNullish from '@helpers/removeNullish';
import { format, formatDistance } from 'date-fns';
import { YOUTUBE_ID_REGEX, URL_REGEX, SPOTIFY_ID_REGEX } from '@helpers/appConsts';

import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { isHandbookProduct } from '@helpers/isProductType';
import * as ApiModels from '@typings/api-models';
import CommentAttachment from '@components/CommentAttachment';
import { useUser } from '@hooks';
import isAttachmentHidden from '@helpers/isAttachmentHidden';
import { Creator } from '@typings/api-models';

import ServiceCard from '@components/ServiceCard';
import RenderText from '../RenderText';
import HandbookMessage from '../HandbookMessage';
import RenderMediaAttachments from '../RenderMediaAttachments';
import BookingLink from './BookingLink';

import s from './CommentContent.module.scss';

type Comment = Pick<ApiModels.Message, 'id' | 'createdAt' | 'isDeleted' | 'text' | 'title'> & {
  type?: ApiModels.MessageType | null;
  bookingLink?: string | null;
  richText?: {
    html: string;
  } | null;
  owner?: {
    id: string;
    name?: string | null;
    image?: {
      url: string;
    } | null;
  } | null;
  parent?: {
    id: string;
    parent?: {
      id: string;
    } | null;
  } | null;
  product?: {
    slug: string;
    productTypes?: {
      noodleProductType?: NoodleProductTypes | null;
    }[];
  } | null;
  taggedUsers?: Pick<ApiModels.Person, 'id' | 'name'>[];
  children?: {
    id: string;
    createdAt: string;
    isDeleted?: boolean | null;
    text?: string | null;
    owner?: {
      id: string;
      name?: string | null;
      image?: {
        url: string;
      } | null;
    } | null;
    children?: {
      id: string;
      createdAt: string;
      isDeleted?: boolean | null;
      text?: string | null;
      owner?: {
        id: string;
        name?: string | null;
        image?: {
          url: string;
        } | null;
      } | null;
    }[];
  }[];
  attachments?: Array<Parameters<typeof CommentAttachment>[0]['attachment']>;
  medias?: Parameters<typeof RenderMediaAttachments>[0]['medias'];
  // products?: MessageProduct[];
  products?: Array<
    Pick<ApiModels.Product, 'id' | 'isActive' | 'slug' | 'shortDescription' | 'title'> & {
      creator?:
        & Pick<ApiModels.Creator, 'slug'>
        & Parameters<typeof ServiceCard>[0]['creator']
        | null
      prices: Array<
        & Pick<ApiModels.Price, 'isActive'>
        & NonNullable<Parameters<typeof HandbookMessage>[0]['handbook']['prices']>[number]
      >;
      image?: Pick<ApiModels.Asset, 'url'> | null;
      productTypes: Array<NonNullable<Parameters<typeof HandbookMessage>[0]['handbook']['productTypes']>[number]>
      handbookSteps?: Array<NonNullable<Parameters<typeof HandbookMessage>[0]['handbook']['handbookSteps']>[number]>
    }
    & Parameters<typeof ServiceCard>[0]['product']
  >;
};

type Props = {
  comment: Comment;
  creator: Pick<Creator, 'slug' | 'personId'>;
  customerId?: string;
  reloadConversation?: () => Promise<void>;
};

const CommentContent: FC<Props> = ({ comment, creator, customerId, reloadConversation }) => {
  const getMediasFromText = useCallback(() => {
    const message = comment?.richText?.html || comment?.text || '';
    const urls = message.match(URL_REGEX) || [];
    return urls
      .map((link: string) => {
        if (link.match(/loom.com/g)) {
          return { id: nanoid(), name: '', type: 'loom', url: link };
        }
        if (link.match(YOUTUBE_ID_REGEX)?.[1]) {
          return { id: nanoid(), name: '', type: 'youtube', youtubeUrl: link };
        }
        if (link.match(SPOTIFY_ID_REGEX)?.[1]) {
          return { id: nanoid(), name: '', type: 'spotify', url: link };
        }
        return null;
      })
      .filter(removeNullish);
  }, [comment]);

  const [user] = useUser();

  const handbooks = comment.products?.filter(p => isHandbookProduct(p));
  const nonHandbookProducts = comment.products?.filter(p => !isHandbookProduct(p));

  const isWorkflowComment = comment.type === ApiModels.MessageType.WorkflowComment;
  const isChat = comment.type === ApiModels.MessageType.Chat;

  return (
    <Fragment>
      {!isWorkflowComment && !isChat && <RenderMediaAttachments commentType={comment.type} medias={comment.medias} />}
      {comment.title && (
        <div className={s.title}>
          <h3 className="heading-sm">{comment.title}</h3>
          <p className="caption" style={{ color: 'var(--color-gray-75)' }}>
            {format(new Date(comment.createdAt), 'MMM dd, yyyy')} · {formatDistance(new Date(comment.createdAt), new Date())} ago
          </p>
        </div>
      )}
      <RenderText comment={comment} />
      {comment.bookingLink && <BookingLink link={comment.bookingLink} />}
      <RenderMediaAttachments medias={getMediasFromText()} />
      {(isWorkflowComment || isChat) && <RenderMediaAttachments medias={comment.medias} commentType={comment.type} />}

      {(handbooks || nonHandbookProducts) && (
        <div className={s.products}>
          {nonHandbookProducts
            ?.filter(p => p.isActive && p.prices.filter(pr => pr.isActive).length > 0)
            .map(h => h.creator
              ? (
                <ServiceCard
                  key={h.id}
                  to={getUrl(IDENTIFIERS.PRODUCT, { creatorSlug: h.creator.slug, productSlug: h.slug })}
                  creator={h.creator}
                  product={h}
                  canEdit={false}
                />
              )
              : null)}
          {comment.attachments?.filter(attachment => !isAttachmentHidden({
            attachment,
            creatorPersonId: creator.personId || null,
            customerId: customerId || null,
            personId: user?.id || null,
          })).map((attachment) => (
            <CommentAttachment
              key={attachment.id}
              attachment={attachment}
              creator={creator}
              reload={reloadConversation}
              isInChat
            />
          ))}
          {handbooks?.map(h => (
            <Fragment key={h.id}>
              <HandbookMessage
                handbook={{
                  handbookSteps: h.handbookSteps,
                  image: {
                    url: h.image?.url,
                  },
                  link: `${SELF_ORIGIN}/${h.creator?.slug}/${h.slug}`,
                  prices: h.prices,
                  productTypes: h.productTypes,
                  shortDescription: h.shortDescription || '',
                  title: h.title || '',
                }}
              />
            </Fragment>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default CommentContent;
