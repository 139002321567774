import { FC, useEffect } from 'react';
import * as tsClient from '@tsClient';
import Spacer from '@components/Spacer/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import ProgressIndicator from '@components/ProgressIndicator';
import InvoiceSettings from '@components/InvoiceSettings';

export type AttachPriceToInvoiceProps = {
  onSubmitCompleted?: () => Promise<void> | void;
  invoiceId: string;
};

const AttachPriceToInvoice: FC<AttachPriceToInvoiceProps> = ({ invoiceId, onSubmitCompleted }) => {
  const { data: invoice, getData: getInvoice } = useNoodleApi(tsClient.invoices.getInvoice);
  const { getData: setLineItemsFn } = useNoodleApi(tsClient.invoices.setInvoiceLineItems);
  const { getData: updateInvoiceFn } = useNoodleApi(tsClient.invoices.updateInvoice);

  useEffect(() => {
    getInvoice({ id: invoiceId });
  }, [getInvoice, invoiceId]);

  const handleSubmit = async ({ invoiceData, lineItems }: { invoiceData: Omit<Parameters<typeof tsClient.invoices.updateInvoice>[0], 'id'>; lineItems: Parameters<typeof tsClient.invoices.setInvoiceLineItems>[0]['lineItems'] }): Promise<void> => {
    await updateInvoiceFn({
      ...invoiceData,
      id: invoiceId,
    });
    await setLineItemsFn({ id: invoiceId, lineItems });
    await onSubmitCompleted?.();
  };

  return (
    <>
      <Spacer size={16} />
      {!invoice && <ProgressIndicator isCentered />}
      {invoice && (
        <InvoiceSettings requireLineItems isInvoice currency={invoice.currency} onSave={handleSubmit} initialValue={invoice} />
      )}
    </>
  );
};

export default AttachPriceToInvoice;
