import { FC, useContext, useEffect } from 'react';
import { DocumentRequest, DocumentRequestStatus, DocumentRequestUser } from '@typings/document-api-models';
import Buttons from '@components/Buttons';
import { useRouter } from 'next/router';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import FileDashed from '@components/Icons/FileDashed';
import Clock from '@components/Icons/Clock';
import Integration from '@components/Icons/Integration';
import File from '@components/Icons/File';
import CheckCircle from '@components/Icons/CheckCircle';
import WarningCircle from '@components/Icons/WarningCircle';
import { DashboardContext } from '@layouts/DashboardLayout';
import { useOpenDocumentRequestUserPane } from '@panes/hooks';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import * as format from '@format';
import s from './UserDocumentRequestCard.module.scss';
import Tooltip from '../Tooltip';

type Props = {
  request: Pick<DocumentRequestUser, 'id' | 'status'> & {
    documentRequest: Pick<DocumentRequest, 'id' | 'title' | 'isForCreator'>;
    actionRequiredFiles: number;
    uploadedRequiredFiles: number;
    totalRequiredFiles: number;
    uploadedFiles: number;
    totalFiles: number;
  };
  canClick: boolean;
  creatorSlug?: string;
  setIsFulfilled?: (f: boolean) => void;
  completedAt?: string | null;
  integratedWith?: 'dol' | 'dos' | 'pacer' | 'uscis';
};

const UserDocumentRequestCard: FC<Props> = ({ setIsFulfilled, creatorSlug, request, canClick, completedAt, integratedWith }) => {
  const router = useRouter();
  const { creator, isInDashboard } = useContext(DashboardContext);
  const { isInUserProfile } = useContext(UserProfileContext);
  const openDocumentRequestUserPane = useOpenDocumentRequestUserPane();

  const handleClick = (): void => {
    if (isInDashboard || isInUserProfile) {
      openDocumentRequestUserPane({ documentRequestUserId: request.id });
    } else {
      router.push(
        getUrl(IDENTIFIERS.DOCUMENT_REQUEST_USER_DETAIL, {
          creatorSlug: creatorSlug || creator?.slug || (router.query.creatorSlug as string) || '',
          documentRequestUserId: request.id,
        }),
      );
    }
  };

  const isAllRequiredUploaded = request.uploadedRequiredFiles === request.totalRequiredFiles;
  const noRequiredFiles = request.totalRequiredFiles === 0;

  const {isForCreator} = request.documentRequest;
  const isUnderReview = isAllRequiredUploaded && !noRequiredFiles && request.status === DocumentRequestStatus.IN_REVIEW;
  const isActionRequired = request.actionRequiredFiles > 0;
  const isCompleted = isAllRequiredUploaded && !noRequiredFiles && request.status === DocumentRequestStatus.SUCCEEDED;

  useEffect(() => {
    setIsFulfilled?.(isCompleted);
  }, [isCompleted, setIsFulfilled]);

  return (
    <Buttons className={s.container} key={request.id} isFullWidth isWrapper onClick={handleClick} disabled={!canClick}>
      <figure style={(isCompleted && { backgroundColor: 'var(--color-primary)', borderColor: 'var(--color-primary)' }) || {}}>
        {!isCompleted && !noRequiredFiles && (
          <svg className={s.progress} width={44} height={44} viewBox="0 0 46 46">
            <circle
              cx={23}
              cy={23}
              r={22}
              style={{
                strokeDasharray: '138',
                strokeDashoffset: `${((request.totalRequiredFiles - request.uploadedRequiredFiles) / request.totalRequiredFiles) * 138}`,
              }}
            />
          </svg>
        )}
        {isAllRequiredUploaded && !noRequiredFiles && request.status === DocumentRequestStatus.SUCCEEDED
          ? (
            <File weight="fill" size={24} color="var(--color-gray-0)" />
          )
          : (
            <FileDashed size={24} color="var(--color-gray-100)" />
          )}
        {isAllRequiredUploaded && !noRequiredFiles && request.status === DocumentRequestStatus.SUCCEEDED && (
          <div className={s.complete}>
            <CheckCircle size={16} weight="fill" color="var(--color-success)" />
          </div>
        )}
        {isUnderReview && !noRequiredFiles && isAllRequiredUploaded && (
          <div className={s.complete}>
            <Clock size={16} weight="fill" color="var(--color-gray-100)" />
          </div>
        )}
        {isActionRequired && (
          <div className={s.complete}>
            <WarningCircle size={16} weight="fill" color="var(--color-warning)" />
          </div>
        )}
      </figure>
      <div>
        <div className={s.type}>
          <p className="caption">Document request{completedAt && isCompleted && ` completed on ${format.datetime.withoutTimezone({datetime:completedAt})}`}</p>
          {integratedWith && <Tooltip text={`Data in this document request is going to eFile with the ${integratedWith.toUpperCase()}`}>
            <Integration weight="fill" color="var(--color-noodle)" size={12} />
          </Tooltip>}
        </div>
        <p className="body-sm-bold">{request.documentRequest?.title}</p>
        {noRequiredFiles
          ? (
            <p className="caption">
              {request.totalFiles} optional document{request.totalFiles > 1 ? 's' : ''}{' '}
              {isUnderReview && request.uploadedFiles > 0 ? 'under review' : ''}
            </p>
          )
          : (
            <p className="caption">
              {isUnderReview && creator && !isForCreator && (
                <>
                  <strong style={{ color: 'var(--color-warning)' }}>Review required by you</strong> ·{' '}
                </>
              )}
              {(isAllRequiredUploaded && !noRequiredFiles && request.status === DocumentRequestStatus.IN_PROGRESS
                && !isActionRequired && !creator && (
                <>
                  All required documents uploaded.{!isForCreator && <strong style={{ color: 'var(--color-warning)' }}>Please submit for review</strong>}
                </>
              )) || (
                <>
                  {!isUnderReview && `${request.uploadedRequiredFiles} of `}
                  {request.totalRequiredFiles} required document{request.totalRequiredFiles > 1 ? 's' : ''}{' '}
                  {isUnderReview && request.uploadedFiles > 0 ? 'under review' : 'uploaded'}
                </>
              )}
            </p>
          )}
      </div>
      {canClick && <div className={s.open} {...((request.status !== DocumentRequestStatus.SUCCEEDED && !creator) || (isUnderReview && creator)) && {
        style: {
          backgroundColor: 'var(--color-primary)',
          border: '1px solid var(--color-primary)',
          color: 'var(--color-gray-0)',
        },
      }}>View</div>}
    </Buttons>
  );
};

export default UserDocumentRequestCard;
