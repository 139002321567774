import { FC, useState, useEffect, useContext, useMemo } from 'react';
import * as ApiModels from '@typings/api-models';
import { useToast } from '@hooks';
import { ToastTypeVariants } from '@context/ToastContext';
import teamsContext from '@providers/Teams/TeamsContext';
import Search from '@components/DesignLibrary/Atoms/Search';
import ListCell from '@components/DesignLibrary/ListCell';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import { m } from 'framer-motion';
import { WorkflowCollaborator } from '@/hooks/useUserWorkflowContext';
import s from './CreditReportModal.module.scss';

type Props = {
  onCustomerSelect: (newCustomer: Pick<ApiModels.Person, 'id' | 'name' | 'email'>) => void;
  selectedCustomer?: Pick<ApiModels.Person, 'id' | 'name' | 'email'> | null;
  nextButtonText?: string;
  customers: WorkflowCollaborator[];
};

const SelectCustomer: FC<Props> = ({ onCustomerSelect, selectedCustomer: existingCustomer, nextButtonText = 'Next', customers }) => {
  const [search, setSearch] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState<Pick<ApiModels.Person, 'id' | 'name' | 'email'> | null>(existingCustomer || null);
  const addToast = useToast();

  const { creatorId } = useContext(teamsContext);

  const handleSearchChange = (newSearch: string): void => {
    setSearch(newSearch);
  };

  useEffect(() => {
    const modal = document.querySelector('#modal-body');
    if (modal) {
      modal.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  }, [selectedCustomer]);

  const handleSubmit = async (): Promise<void> => {
    if (!creatorId) {
      addToast(ToastTypeVariants.ERROR, 'Unable to determine creator team. Please try refreshing the page or contact support.');
      return;
    }
    if (selectedCustomer) {
      onCustomerSelect(selectedCustomer);
    }
  };

  const searchedCustomers = useMemo(() => customers
    .filter((customer) => (customer.name??'').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1), [search, customers]);

  return (
    <div className={s.contentFluidX}>
      <Search onChange={handleSearchChange} />
      {searchedCustomers
        // move selected customer to the top of the list
        .sort((a, b) => {
          if (a.id === selectedCustomer?.id) {
            return -1;
          }
          if (b.id === selectedCustomer?.id) {
            return 1;
          }
          return 0;
        })
        .map(customer => (
          <m.div layout="position" key={customer.id}>
            <ListCell
              tag='div'
              onClick={() => setSelectedCustomer(customer)}
              isSelected={selectedCustomer?.id === customer.id}
              avatar={{
                color: customer.color ?? '',
                name: customer.name || '',
              }}
              bottomLine={customer.email}
              label={customer.name || ''}
            />
          </m.div>
        ))}
      <Spacer size="16px" />

      <div className={s.selectCustomerButton}>
        <Button variant="primary" size="md" fullWidth disabled={!selectedCustomer} onClick={handleSubmit}>
          {nextButtonText}
        </Button>
      </div>
    </div>
  );
};

export default SelectCustomer;
