const ChecksOverlapping: React.FC = () => (
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.602 8.75756L12.014 10.1696L20.48 1.70356L21.894 3.11756L12.014 12.9976L5.65 6.63356L7.064 5.21956L9.189 7.34456L10.602 8.75656V8.75756ZM10.604 5.92956L15.556 0.976562L16.966 2.38656L12.014 7.33956L10.604 5.92956ZM7.777 11.5846L6.364 12.9976L0 6.63356L1.414 5.21956L2.827 6.63256L2.826 6.63356L7.777 11.5846Z"
      fill="#010101"
    />
  </svg>
);

export default ChecksOverlapping;