import { useEffect, ReactElement } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import Check from '@/components/Icons/Check';
import Spacer from '@components/Spacer';
import * as format from '@format';

import { NoodleProductPrice } from '@/typings/api-models';
import CreditFinancialReportCard from './CreditFinancialReportCard';
import s from './CreditReportModal.module.scss';

const DETAILS = [
  'Primary property value with comparables, property deeds and assessments, related addresses',
  'Registrations for all titled vehicles',
  'UCC filings and property deeds',
  'Experian, TransUnion, and Equifax creditor and public records data with bankruptcy-specific addresses',
  'LexisNexis 8-year bankruptcy, liens, and judgments search',
  'Current and predictive post-bankruptcy credit score for the primary and joint debtor',
];

type Product = {
  productId: string;
  price: number;
  currency: string;
  numBookings: number;
};

type Props = {
  onSelectProduct: ({ productId, price, currency, numBookings }: Product) => void;
  nextStep: () => void;
};

const CreditFinancialReport = ({ onSelectProduct, nextStep }: Props): ReactElement => {
  const { data: productPrices, fetchingState, getData: getProductPrices } = useNoodleApi(tsClient.creditReportsB2C.getNoodleProducts);

  useEffect(() => {
    getProductPrices();
  }, [getProductPrices]);

  const handleClick = (productId: string, price: number, currency: string, numBookings: number): void => {
    onSelectProduct({ currency, numBookings, price, productId });
    nextStep();
  };

  if (fetchingState.isFetching) {
    return <ProgressIndicator isCentered />;
  }

  return (
    <div>
      <div className={s.reportCards}>
        {productPrices?.filter((product: NoodleProductPrice) => product.isActive).map((product: NoodleProductPrice) => (
          <CreditFinancialReportCard
            key={product.id}
            title={product.priceTitle ?? ''}
            description={product.priceDescription?.html ?? ''}
            buttonLabel={`Buy for ${format.price.withCurrency(product.price, product.currency)}`}
            onClick={() => handleClick(product.id, product.price, product.currency, product.numBookings ?? 1 )}
          />
        ))}
      </div>
      <Spacer size={16} />

      <div className="body-sm-bold">What’s included:</div>
      <Spacer size={8} />
      {DETAILS.map((item, itemKey) => (
        <div key={itemKey} className={s.reportDetails}>
          <Check size={12} color="var(--color-success)" />
          <div className="caption">{item}</div>
        </div>
      ))}
    </div>
  );
};

export default CreditFinancialReport;
