import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { Creator, FormProviders, FormRequest, FormRequestTemplate, FormStatus, MESSAGE_ATTACHMENT_REFERENCE_TYPES, Person } from '@typings/api-models';
import ListChecks from '@components/Icons/ListChecks';
import ListBullets from '@components/Icons/ListBullets';
import CheckCircle from '@components/Icons/CheckCircle';
import Integration from '@components/Icons/Integration';
import Buttons from '@components/Buttons';
import { useUser } from '@hooks';
import * as tsClient from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { DashboardContext } from '@layouts/DashboardLayout';
import SkipTaskConfirmationModal from '@modals/SkipTaskConfirmationModal';
import { useOpenFormRequestPane } from '@panes/hooks';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import * as format from '@format';
import s from './FormRequestCard.module.scss';
import Tooltip from '../Tooltip';

type Props = {
  creator: Pick<Creator, 'slug'>;
  request: Pick<FormRequest, 'id' | 'url' | 'status'> & {
    template: Pick<FormRequestTemplate, 'id' | 'name' | 'provider'>;
    person: Pick<Person, 'id'>;
  };
  canClick: boolean;
  canSkip: boolean;
  setIsFulfilled?: (b: boolean) => void;
  setIsSkipped?: (b: boolean) => void;
  onChange?: () => void;
  completedAt?: string | null;
  integrationSlugs?: string[];
};

const FormRequestCard: React.FC<Props> = ({
  setIsFulfilled, setIsSkipped, onChange, canClick, canSkip, creator, request, completedAt, integrationSlugs,
}) => {
  const router = useRouter();
  const [user] = useUser();
  const openFormRequestPane = useOpenFormRequestPane();

  const [skipTaskModalOpen, setSkipTaskModalOpen] = useState(false);
  const { isInDashboard } = useContext(DashboardContext);
  const { isInUserProfile } = useContext(UserProfileContext);

  const { getData: updateFormRequest } = useNoodleApi(tsClient.formRequests.updateFormRequest, {
    toastOnError: true,
  });

  const isCompleted = request.status === FormStatus.COMPLETED;
  const isSkipped = request.status === FormStatus.SKIPPED;

  useEffect(() => {
    setIsFulfilled?.(isCompleted);
  }, [isCompleted, setIsFulfilled]);

  useEffect(() => {
    setIsSkipped?.(isSkipped);
  }, [isSkipped, setIsSkipped]);

  const handleClick = (): void => {
    if (isInDashboard) {
      openFormRequestPane({ formRequestId: request.id });
    } else if (user && request.person.id === user.id && !isCompleted && request.template.provider === FormProviders.Typeform) {
      window.open(request.url, '_blank');
    } else if (isInUserProfile) {
      openFormRequestPane({ formRequestId: request.id });
    } else {
      router.push(getUrl(IDENTIFIERS.FORM_REQUEST_DETAILS, { creatorSlug: creator.slug, formRequestId: request.id }));
    }
  };

  const handleSkipTask = async (): Promise<void> => {
    await updateFormRequest({
      formRequestId: request.id,
      status: FormStatus.SKIPPED,
    });
    setSkipTaskModalOpen(false);
    if (onChange) onChange();
  };

  return (
    <>
      {skipTaskModalOpen && (
        <SkipTaskConfirmationModal
          onClose={() => setSkipTaskModalOpen(false)}
          onConfirm={handleSkipTask}
          taskType={MESSAGE_ATTACHMENT_REFERENCE_TYPES.FORM_REQUEST}
        />
      )}
      <Buttons
        className={s.container}
        isFullWidth
        isWrapper
        key={request.id}
        onClick={handleClick}
        disabled={!canClick || (isSkipped && !canSkip)}
        style={isSkipped ? { backgroundColor: 'var(--color-gray-25)', borderRadius: 8 } : {}}
      >
        <figure style={(isCompleted && { backgroundColor: 'var(--color-primary)', borderColor: 'var(--color-primary)' }) || {}}>
          {isCompleted ? <ListChecks size={24} color="var(--color-gray-0)" /> : <ListBullets size={24} color="var(--color-gray-100)" />}
          {isCompleted && (
            <div className={s.complete}>
              <CheckCircle size={16} weight="fill" color="var(--color-success)" />
            </div>
          )}
        </figure>
        <div>
          <div className={s.type}>
            <p className='caption'>Form request{completedAt && ` completed on ${format.datetime.withoutTimezone({datetime:completedAt})}`}</p>
            {isInDashboard && (integrationSlugs?.length || 0) > 0 && <Tooltip text={`Data in this form is going to eFile with the ${format.csl(integrationSlugs?.map(slug => slug.toUpperCase()) || [])}`}>
              <Integration weight="fill" color="var(--color-noodle)" size={12} />
            </Tooltip>}
          </div>
          <p className="body-sm-bold">{request.template.name}</p>
          {isSkipped && <p className='caption'>This task is no longer required.</p>}
        </div>
        {canClick && (!isSkipped || canSkip) && <div className={s.open} {...!isCompleted && !isInDashboard && {
          style: {
            backgroundColor: 'var(--color-primary)',
            border: '1px solid var(--color-primary)',
            color: 'var(--color-gray-0)',
          },
        }}>View</div>}
      </Buttons>
      {canSkip && !isSkipped && (
        <Buttons
          onClick={(event) => {
            event.stopPropagation();
            setSkipTaskModalOpen(true);
          }}
          className={s.skip}
        >
          Skip
        </Buttons>
      )}
    </>
  );
};

export default FormRequestCard;
