const EMAIL_REGEX = /([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+)/;

const convertEmailsToAnchors = (message: string): string => {
  let restOfMessage = message;
  let messageWithAnchors = '';
  let previousLength = -1;

  // break out of loop if there is nothing more to look at, or (as circuit breaker) the message didn't grow.
  while (restOfMessage !== '' && messageWithAnchors.length > previousLength) {
    previousLength = messageWithAnchors.length;
    const match = restOfMessage.match(EMAIL_REGEX);
    if (!match || match.index === undefined) {
      messageWithAnchors = `${messageWithAnchors}${restOfMessage}`;
      restOfMessage = '';
    } else {
      const beforeEmail = restOfMessage.slice(0, match.index);
      const email = match[0];
      const anchor = `<a href="mailto:${email}">${email}</a>`;
      messageWithAnchors = `${messageWithAnchors}${beforeEmail}${anchor}`;
      restOfMessage = restOfMessage.slice(match.index + email.length);
    }
  }

  return messageWithAnchors;
};

export default convertEmailsToAnchors;