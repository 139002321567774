import { FC, useEffect, useState } from 'react';
import CustomerSchedulingCalendar from '@components/CustomerSchedulingCalendar';
import useNoodleApi from '@hooks/useNoodleApi';
import { getProductById } from '@tsClient';
import * as ApiModels from '@typings/api-models';
import commonStyles from '@modals/ModalsCommon.module.scss';
import ProgressIndicator from '@components/ProgressIndicator';
import Spacer from '@components/Spacer';
import UserImage from '@components/UserImage';
import * as format from '@format';
import Button from '@components/DesignLibrary/Button';
import getTeamMembersWithAvailability from '@/tsClient/scheduling/getTeamMembersWithAvailability';
import Chips from '@/components/Chips';
import s from './ScheduleBooking.module.scss';

export type ScheduleBookingProps = {
  booking: Pick<ApiModels.Booking, 'productId'> & Parameters<typeof CustomerSchedulingCalendar>[0]['booking'] & {
    price: Pick<ApiModels.Price, 'id' | 'priceTitle'> & {
      product: Pick<ApiModels.Product, 'id' | 'title'>;
    };
    person: Pick<ApiModels.Person, 'id' | 'name'> & {
      primaryColour?: { hex: string } | null;
      image?: { url: string } | null;
    };
    creator: Pick<ApiModels.Creator, 'id' | 'timezone'>;
  };
  teamMemberPersonId?: string;
  onSchedule?: () => Promise<void>;
};

const ScheduleBookingModal: FC<ScheduleBookingProps> = ({ booking, onSchedule, teamMemberPersonId }) => {
  const [screen, setScreen] = useState<'Book' | 'Review'>(booking.startAt ? 'Review' : 'Book');
  const [selectedTeamMemberPersonId, setSelectedTeamMemberPersonId] = useState<string | null>(teamMemberPersonId || null);

  const {
    data: productData,
    fetchingState: { isFetching: isFetchingProduct },
    getData: getProduct,
  } = useNoodleApi(getProductById);
  const { getData: getTeamMembersWithAvailabilityFn, data: teamMembers } = useNoodleApi(getTeamMembersWithAvailability);

  useEffect(() => {
    getProduct({ id: booking.productId });
  }, [booking, getProduct]);

  useEffect(() => {
    if (productData && productData.product.id) {
      getTeamMembersWithAvailabilityFn({ productId: productData.product.id });
    }
  }, [productData, getTeamMembersWithAvailabilityFn]);

  useEffect(() => {
    if (teamMembers && teamMembers.length > 0 && !teamMemberPersonId) {
      setSelectedTeamMemberPersonId(teamMembers[0].id);
    }
  }, [teamMembers, teamMemberPersonId, setSelectedTeamMemberPersonId]);

  const handleBookingScheduled = async (): Promise<void> => {
    await onSchedule?.();
  };

  const isButtonDisabled = booking.startAt
    ? new Date(new Date(booking.startAt).getTime() - 1000 * 60 * 60 * 24 * 2) < new Date()
    : false;

  return (
    <div className={commonStyles.container}>
      {screen === 'Book' && (
        <>
          {(isFetchingProduct || !productData?.product)
            ? <ProgressIndicator isCentered />
            : (
              <div className={s.calendar}>
                {teamMembers && !teamMemberPersonId && (
                  <Chips
                    data={teamMembers.map((tm) => ({
                      id: tm.id,
                      label: tm.name || tm.email || tm.id,
                      userImage: {
                        color: tm.primaryColour?.hex,
                        name: tm.name || undefined,
                        url: tm.image?.url,
                      },
                    }))}
                    wrap
                    selectedItem={selectedTeamMemberPersonId}
                    onItemClick={(id) => id && setSelectedTeamMemberPersonId(id)}
                  />
                )}
                <CustomerSchedulingCalendar
                  availabilityTimezone={booking.creator.timezone}
                  priceId={booking.priceId}
                  product={productData?.product}
                  selectedTeamMemberPersonId={selectedTeamMemberPersonId}
                  booking={booking}
                  onFinish={handleBookingScheduled}
                />
              </div>
            )}
        </>
      )}
      {screen === 'Review' && (
        <div className={s.review}>
          <div className={s.content}>
            <p className="body-lg" style={{ textAlign: 'center' }}>
              {booking.price.priceTitle || booking.price.product.title || 'Booking'}
            </p>
            <Spacer size="16px" />
            <p className="caption" style={{ color: 'var(--color-gray-75)', textAlign: 'center' }}>
              Contact
            </p>
            <Spacer size="8px" />
            <div className={s.customer}>
              <UserImage size={24} color={booking.person.primaryColour?.hex || null} name={booking.person.name} url={booking.person.image?.url} />
              <p className="body-sm">{booking.person.name}</p>
            </div>
            <Spacer size="16px" />
          </div>
          {booking.startAt && (
            <div className={s.reviewCell}>
              <p className="caption">
                Date & Time:
              </p>
              <Spacer size="16px" />
              <p className='body-sm'>{format.datetime.withTimezone({ datetime: booking.startAt, timezone: booking.creator.timezone })}</p>
            </div>
          )}
          <Spacer size="16px" />
          <Button variant="primary" disabled={isButtonDisabled} size="md" fullWidth onClick={() => setScreen('Book')}>
            {isButtonDisabled ? 'Can not reschedule within 48 hours' : 'Reschedule'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ScheduleBookingModal;
